<div class="ss-layout-container" kendoDialogContainer>
  <kendo-drawer-container>
    <!-- DRAWER -->
    <kendo-drawer
      #drawer
      mode="push"
      [(expanded)]="currentUser().isCurrentlyLogged && expanded"
      (expandedChange)="onExpandedChange($event)"
      class="ss-layout-drawer"
    >
      <ng-template kendoDrawerTemplate
        ><app-sidenav [expanded]="expanded"></app-sidenav
      ></ng-template>
    </kendo-drawer>
    <!-- MAIN -->
    <kendo-drawer-content>
      <div class="ss-layout-main">
        <!-- HEADER - s'affiche qu'on soit loggé ou non : Logo, changement de lnague, etc..-->
        <div class="ss-layout-header">
          <app-header (toggleSidenav)="drawer.toggle()"></app-header>
        </div>
        <!-- CONTENT -->
        <div class="ss-layout-content">
          <router-outlet></router-outlet>
        </div>
      </div>
    </kendo-drawer-content>
  </kendo-drawer-container>
</div>
