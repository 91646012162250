<kendo-dialog-titlebar (close)="cancel()">
  <div style="font-size: 18px; line-height: 1.3em">
    {{ title | translate }}
  </div>
</kendo-dialog-titlebar>
<!-- CONTENT -->

<div class="ss-popup-container">
  <kendo-grid
    [data]="gridView"
    [pageSize]="state.take"
    [skip]="state.skip"
    [sort]="state.sort"
    [filter]="state.filter"
    [sortable]="true"
    [pageable]="pageable"
    [filterable]="true"
    [resizable]="true"
    [selectable]="gridService.selectableSettings"
    scrollable="scrollable"
    [loading]="isLoading"
    class="ss-list-grid"
    (cellClick)="select($event)"
    (dataStateChange)="dataStateChange($event)"
  >
    <!-- COLUMNS -->

    <!-- ID -->

    <kendo-grid-column
      field="customerId"
      title="{{ 'module.customer.model.customerId' | translate }}"
      [width]="40"
      filter="numeric"
      format="0n0"
    ></kendo-grid-column>

    <!-- NAME -->

    <kendo-grid-column
      field="customerName"
      title="{{ 'module.customer.model.customerName' | translate }}"
      [width]="120"
    ></kendo-grid-column>

    <!-- IS ACTIVE -->

    <kendo-grid-column
      field="isActiveInSafestock"
      title="{{ 'module.customer.model.isActiveInSafestock' | translate }}"
      [width]="20"
      filter="boolean"
      ><ng-template kendoGridCellTemplate let-dataItem>
        <kendo-svg-icon *ngIf="dataItem.isActiveInSafestock" [icon]="allIcons.checkIcon" themeColor="primary"></kendo-svg-icon>
      </ng-template
    ></kendo-grid-column>
  </kendo-grid>
</div>
