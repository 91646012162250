<kendo-dialog
  title="{{ 'module.customer.import.title' | translate }}"
  (close)="close()"
  [width]="dialogWidth"
  [height]="dialogHeight"
  class="ss-order-edit-product-dialog-content"
  [animation]="false"
>

  <!-- ETAPE 1 : SÉLECTIONNER UN FICHIER ------------------------------------------ -->

  <div class="ss-import-grid-container" *ngIf="step == 1">
    <!-- MESSAGE -->

    <div class="ss-import-message">
      {{ "module.customer.import.message_file" | translate }}
    </div>

    <div class="ss-import-file">
      <div
        class="ss-import-file-drop-zone"
        kendoUploadDropZone="uploadZone"
      >
        {{ "module.customer.import.message_drop_file_here" | translate }}
      </div>
      <kendo-fileselect
        zoneId="uploadZone"
        [multiple]="false"
        (valueChange)="onFileChage($event)"
      >
      </kendo-fileselect>
    </div>
  </div>

  <!-- ETAPE 2 : CONFIRMER ET IMPORTER ------------------------------------------ -->

  <div class="ss-import-grid-container" *ngIf="step == 2">
    <!-- MESSAGE -->

    <div class="ss-import-message-warning">
      {{ "module.customer.import.message_confirm" | translate }}
    </div>

    <div class="ss-import-confirm">
      <div class="ss-import-confirm-value">
        {{ "module.customer.import.label_customer" | translate }} : <b>{{ customerName }}</b>
      </div>
      <div class="ss-import-confirm-value">
        {{ "module.customer.import.label_file" | translate }} : <b>{{ file.name }}</b>
      </div>
    </div>
  </div>

  <!-- BUTTONS -->
  <kendo-dialog-actions *ngIf="step == 2">
    <button
      kendoButton
      themeColor="primary"
      [svgIcon]="allIcons.xIcon"
      fillMode="flat"
      (click)="close()"
    >
      {{ "shared.button.cancel" | translate }}
    </button>
    <button kendoButton themeColor="primary" [svgIcon]="allIcons.checkIcon" (click)="confirm()">
      {{ "module.customer.import.button_confirm" | translate }}
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
