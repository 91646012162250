import { Component, OnInit } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import * as svgIcons from '@progress/kendo-svg-icons';

@Component({
  selector: 'app-customer-import',
  templateUrl: './customer-import.component.html',
  styleUrls: ['./customer-import.component.scss'],
})
export class CustomerImportComponent implements OnInit {
  public allIcons = svgIcons;

  dialogWidth = 1000;
  dialogHeight = 800;
  customerName = '';
  step = 0; // 1: file select, 2: confirm & submit
  file!: any;

  constructor(
    public dialog: DialogRef,
  ) { }

  ngOnInit(): void {
    this.goToStep(1);
  }

  onAction(e: any) {}

  close() {
    this.dialog.close();
  }

  goToStep(step: number) {
    if (step == 1) {
      this.dialogWidth = 800;
      this.dialogHeight = 600;
    } else if (step == 2) {
      this.dialogWidth = 800;
      this.dialogHeight = 400;
    }
    this.step = step;
  }

  onFileChage(e: any) {
    this.file = e[0];
    this.goToStep(2);
  }

  confirm() {
    this.dialog.close({
      file: this.file,
    });
  }
}
