import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, mergeMap, BehaviorSubject, forkJoin, combineLatest, map } from 'rxjs';
import { environment } from 'src/environments/environment';

import { catchError, retry, switchMap, take, tap } from 'rxjs/operators';

import { CartLine } from './global-data-models/cart-line.model';
import { ProductItem } from './global-data-models/product-item.model';
import { Property } from './global-data-models/property.model';
import { LocalStorage } from 'src/app/core/local-storage.enum';
import { UtilService } from 'src/app/shared/util.service';
import { ProductRestrictions } from './global-data-models/product-restrictions.model';
import { UserGroup } from 'src/app/module/user-group/user-group.model';
import { UserOrderOverview } from './global-data-models/user-order-overview.model';
import { UserOverride } from './global-data-models/user-override.model';
import { AuthService } from 'src/app/core/auth/auth.service';
import { PermissionService } from 'src/app/core/permission.service';
import { Customer } from 'src/app/module/customer/customer.model';
import { CustomerService } from 'src/app/module/customer/customer.service';
import { Product } from 'src/app/module/catalog/product.model';
import { ProductInformationManagementService } from '../product-information-management/product-information-management.service';
import { UserService } from 'src/app/module/user/user.service';

@Injectable({
  providedIn: 'root',
})

export class GlobalDataService {


  private BASE_URL = environment.apiUrl;
  private CATALOG_URL = `${this.BASE_URL}Product/Catalog`
  private SECURITY_URL = `${this.BASE_URL}security/UserAccount`

  public cart: BehaviorSubject<CartLine[]> = new BehaviorSubject<CartLine[]>([]);
  public cartItems: BehaviorSubject<ProductItem[]> = new BehaviorSubject<ProductItem[]>([]);
  public productRestrictions: BehaviorSubject<ProductRestrictions[]> = new BehaviorSubject<ProductRestrictions[]>([])
  public userGroupRestrictions: BehaviorSubject<UserGroup> = new BehaviorSubject<UserGroup>(new UserGroup())
  public userOrderOverview: BehaviorSubject<UserOrderOverview> = new BehaviorSubject<UserOrderOverview>(new UserOrderOverview(-1, -1, -1, new Date(), -1, -1, -1, -1, {}))
  public userOverride: BehaviorSubject<UserOverride> = new BehaviorSubject<UserOverride>(new UserOverride(null, null, null))
  public customerDefaultShipToId: BehaviorSubject<string> = new BehaviorSubject<string>('-1')
  public currentCustomerId: BehaviorSubject<number> = new BehaviorSubject<number>(0)
  public budgetAmountLimitationActivated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  constructor(
    private http: HttpClient,
    private utilService: UtilService,
    private authService: AuthService,
    private permissionService: PermissionService,
    private pimService: ProductInformationManagementService,
    private customerService: CustomerService,
    private userService: UserService
  ) { }

  //SECTION Fetch http data
  private getUserGroupCatalog(): Observable<any> {
    let userGroupId = this.authService.GetCurrentUser().currentUserGroupHeaderId;
    return this.http.get<any>(`${this.CATALOG_URL}/GetProductCatalogByUserGroup/${userGroupId}`)
  }

  private getUserOrderOverview(): Observable<UserOrderOverview> {
    let userId = this.authService.GetCurrentUser().userId;
    return this.http.get<UserOrderOverview>(`${this.SECURITY_URL}/${userId}/GetUserOrderOverview`)
  }

  private getUserProductRestrictions() {
    let userId = this.authService.GetCurrentUser().userId;
    return this.http.get<any>(`${this.SECURITY_URL}/${userId}/GetUserProductRestrictions`)
  }

  private getUserOverride(): Observable<UserOverride> {
    let userId = this.authService.GetCurrentUser().userId;
    let uri = `${this.SECURITY_URL}?$filter=id eq '${userId}'` +
      `&$select=overrideEmployeMinimumAmountToSkipTransportFee,` +
      `overrideEmployeTransportFeeAmount,overrideEmployeMaximumAllowedAmount`

    return this.http.get<any>(uri).pipe(mergeMap(response => {
      let data: UserOverride = response.value[0]
      return of(data)
    }))
  }

  public getDefaultCustomerShipToId(customerId = this.currentCustomerId.getValue()) {
    let uri = `${this.BASE_URL}Customer/Customers/${customerId}`
    return this.http.get<any>(uri).pipe(
      mergeMap((res: any) => {
        let shipToId = res.defaultShipToId
        shipToId = shipToId ? shipToId : '-1'

        return of(shipToId)
      }),
      catchError((err: HttpErrorResponse) => {
        throw err
      }),
      retry(2)
    )
  }

  /**
   * Permet d'aller chercher de l'information sur les données globale de l'usager (Info sur le client par défaut, restriction de produit, ovveride de l'usager, etc....)
   */
  public loadGlobalData(): Observable<any> {
    this.clearGlobalData();

    if (this.permissionService.canLoadShopperGlobalData() && this.permissionService.mustApplyUserGroupRestriction()) {
      return forkJoin({
        CustomerId: this.initCurrentCustomerId(),
        ProductRestriction: this.initLoadProductRestrictions(),
        UserGroupHeaderInfo: this.initloadUserGroupHeaderInfo(),
        Cart: this.initLoadCart(),
        UserOrderOverview: this.initloadUserOrderOverview(),
        UserOverride: this.initUserOverride(),
        CustomerDefaultShipToId: this.initCustomerShipToId(),
        CartItems: this.restoreCartItems()
      })
    }
    else if (this.permissionService.canLoadShopperGlobalData()) {
      return forkJoin({
        CustomerId: this.initCurrentCustomerId(),
        Cart: this.initLoadCart(),
        UserOrderOverview: this.initloadUserOrderOverview(),
        UserOverride: this.initUserOverride(),
        CustomerDefaultShipToId: this.initCustomerShipToId(),
        CartItems: this.restoreCartItems()
      })
    }
    else {
      return this.initloadUserOrderOverview()
    }
  }

  private getCustomerCatalog(): Observable<any> {
    let customerId = this.currentCustomerId.getValue()
    return this.http.get<any>(`${this.CATALOG_URL}/GetProductCatalogByCustomer/${customerId}`)
  }

  private initLoadCart(): Observable<CartLine[]> {
    let cart: CartLine[] = [];
    let ls = localStorage.getItem(LocalStorage.CART)
    if (ls != null && ls.length > 0) {
      cart = JSON.parse(ls!) as CartLine[]
    }

    this.cart.next(cart)

    return of(cart)
  }

  private initLoadProductRestrictions(): Observable<ProductRestrictions[]> {
    let restrictions: ProductRestrictions[] = [];
    let ls = localStorage.getItem(LocalStorage.PRODUCT_RESTRICTIONS)

    if (ls != null && ls.length > 0) {
      restrictions = JSON.parse(ls!) as ProductRestrictions[]
      this.setProductRestrictions(restrictions)
      return of(restrictions)
    }
    else {
      return this.getUserProductRestrictions().pipe(mergeMap((products: any) => {

        for (const product of products) {
          restrictions.push(new ProductRestrictions(product.productId, product.productGroupingName, product.maximumQty))
        }

        this.setProductRestrictions(restrictions)

        return of(restrictions)
      }))
    }
  }

  private initloadUserGroupHeaderInfo(): Observable<UserGroup> {
    let ls = localStorage.getItem(LocalStorage.USERGROUP_RESTRICTIONS)
    let info: UserGroup

    if (ls != null && ls.length > 0) {
      info = JSON.parse(ls!) as UserGroup
      this.setUserGroupRestrictions(info)
      return of(info)
    }
    else {
      let userGroupId = this.authService.GetCurrentUser().currentUserGroupHeaderId;
      return this.http.get<any>(`${this.BASE_URL}Customer/UserGroupHeader/${userGroupId}`).pipe(mergeMap((ugr: UserGroup) => {
        info = ugr;
        this.setUserGroupRestrictions(info)
        return of(info)
      }))
    }
  }

  public initloadUserOrderOverview(): Observable<UserOrderOverview> {
    let ls = localStorage.getItem(LocalStorage.USER_ORDER_OVERVIEW)
    let info: UserOrderOverview

    if (ls != null && ls.length > 0) {
      info = JSON.parse(ls!) as UserOrderOverview
      let infoCorrected = this.UserOrderOverviewCorrection(info)
      this.setUserOrderOverview(infoCorrected)
      return of(infoCorrected)
    }
    else {
      return this.getUserOrderOverview().pipe(mergeMap(uoo => {
        let uooCorrected = this.UserOrderOverviewCorrection(uoo)
        this.setUserOrderOverview(uooCorrected)
        return of(uooCorrected)
      }))
    }
  }

  /**
   * Si on reçoit null dans amountRemaining et maxAvailable, ont doit corriger pour mettre infinity via le constructeur
   * @param uoo UserOrderOverview
   * @returns UserOrderOverview corrigé
   */
  private UserOrderOverviewCorrection(uoo: UserOrderOverview): UserOrderOverview {
    let uooCorrected: UserOrderOverview = new UserOrderOverview(
      uoo.maxAvailableAmount,
      uoo.amountRemaining,
      uoo.actualPurchasedAmount,
      uoo.minDateToCheck,
      uoo.nbOrderTotal,
      uoo.nbOrderNotApproved,
      uoo.nbOrderCompleted,
      uoo.nbOrderInCartMode,
      uoo.qtyConsumedByProductId,
    )
    return uooCorrected
  }

  private initUserOverride(): Observable<UserOverride> {
    let ls = localStorage.getItem(LocalStorage.USER_OVERRIDE)
    let info: UserOverride

    if (ls != null && ls.length > 0) {
      info = JSON.parse(ls!) as UserOverride
      this.setUserOverride(info)
      return of(info)
    }
    else {
      return this.getUserOverride().pipe(mergeMap(data => {
        this.setUserOverride(data)
        return of(data)
      }))
    }
  }

  private initCustomerShipToId(): Observable<string> {
    let ls = localStorage.getItem(LocalStorage.CUSTOMER_SHIPTO_ID)
    let info: string

    if (ls != null && ls.length > 0) {
      info = JSON.parse(ls!) as string
      this.setCustomerShipToId(info)
      return of(info)
    }
    else {
      return this.getDefaultCustomerShipToId().pipe(mergeMap((shipToId: string) => {
        this.setCustomerShipToId(shipToId)
        return of(shipToId)
      }))
    }
  }

  private initCurrentCustomerId() {
    let ls = localStorage.getItem(LocalStorage.CURRENT_CUSTOMER_ID)
    let info: number

    if (ls != null && ls.length > 0) {
      info = JSON.parse(ls!) as number
      this.setCurrentCustomerId(info)
      return of(info)
    }
    else {
      let x = this.authService.GetCurrentUser().currentCustomerId;
      this.setCurrentCustomerId(x)
      return of(x)
    }
  }

  private restoreCartItems() {
    let ls = localStorage.getItem(LocalStorage.CART_ITEMS_DETAILS)
    let info: ProductItem[]

    if (ls != null && ls.length > 0) {
      info = JSON.parse(ls!) as ProductItem[]
      this.setCartItems(info)
      return of(info)
    }
    else {
      this.setCartItems([])
      return of([])
    }
  }

  //SECTION Fetch Cart items detail component data

  public getProductList(): Observable<ProductItem[]> {
    if (this.permissionService.canGetProductCatalogByUserGroup()) {
      return this.getProductListEmploye()
    }
    else if (this.permissionService.canGetProductCatalogByCustomer()) {
      return this.getProductListSupervisor()
    }
    else return of([])
  }
  /**
   *
   * @returns les produits du groupe utilsiateurt de l'employé
   */
  private getProductListEmploye(): Observable<ProductItem[]> {
    return this.getUserGroupCatalog().pipe(mergeMap((userGroupInfo: any) => {
      return this.innerGetProduct(userGroupInfo)
    }))
  }
  /**
   * Les superviseurs doivent aller chercher les produits via l'ednpoint get customer catalog
   * @returns Les produits du catalogue du client
   */
  private getProductListSupervisor(): Observable<ProductItem[]> {
    return this.getCustomerCatalog().pipe(mergeMap((_data) => {
      return this.innerGetProduct(_data)
    }))
  }

  /**
   * Code réutilisable pour aller chercher les propriétés des produits fetcher juste avant
   *
   * ATTENTION! Cette méthode va aussi recalculer les prix de InforCSD!
   * @param _data produits ventant de getCustomerCatalog ou de getUserGroupCatalog
   * @returns un array de productItems
   */
  private innerGetProduct(_data: any): Observable<ProductItem[]> {
    let _cart = this.cart.getValue();

    // test pour retourne un empty array si le cart est vide
    if (_cart.length < 1) {
      return of([])
    }

    let mycustomerId = this.currentCustomerId.getValue();

    //construction d'une querry OData pour get selon les itemIds données
    let productItemIds: number[] = _cart.map(x => x.itemId)
    let paramString: string = `productItemId in (${[...productItemIds]})`;

    let uri = `${this.BASE_URL}Product/ProductItems?$filter=${paramString}`;

    let MyProductItemQuery = this.http.get<any>(uri)
      .pipe(
        map((response: any) => response.value))
      ;
    let myPriceQuery = this.getProductItemsPrice(productItemIds);
    let MycustomerProductQuery = this.customerService.getCustomerItemCodes(mycustomerId);

    let asyncData = combineLatest([MyProductItemQuery, myPriceQuery, MycustomerProductQuery]);

    return asyncData.pipe(mergeMap(([_products, myPrices, customProductInfo]) => {
      const ITEMS = _products;
      let productItems: ProductItem[] = [];


      for (const item of ITEMS) {

        //ici, on va trouver notre prix InforCSD
        let MyResults = myPrices.find((e: any) => e.productItemId == item.productItemId);
        let MyProductCustomer = customProductInfo.find(e => e.productItemId == item.productItemId);

        // trouver l'index du produit parent
        let index: number = 0
        for (let w = 0; w < _data.length; w++) {
          let product = _data[w];
          if (product.productId === item.parentProductID) {
            index = w
          }
        }

        let possibleProps: string[] = Object.keys(item).filter((prop: string) => this.getListOfAllPossibleFilteringProperties().indexOf(prop) !== -1)
        let props = possibleProps.filter((x: string) => item[x] != null)
        let properties: Property[] = []

        for (const prop of props) {
          properties.push(new Property(prop, item[prop]))
        }

        // trouver la description du produit, si pas de description dans ProductItem, prendre la description du parent
        let productTitleFr = item.productItemTitleFr ? item.productItemTitleFr : _data[index]?.product?.productTitleFr;
        let productTitleEn = item.productItemTitleEn ? item.productItemTitleEn : _data[index]?.product?.productTitleEn;

        let MyNewItem = new ProductItem();

        MyNewItem.productId = item.parentProductID;
        MyNewItem.productItemId = item.productItemId;
        MyNewItem.imageURL = item.productItemMainImageUrl;
        MyNewItem.productTitleFr = productTitleFr;
        MyNewItem.productTitleEn = productTitleEn;
        MyNewItem.listOfProperties = properties;
        MyNewItem.productItemNumber = item.productItemNumber;

        if (MyResults){
          MyNewItem.itemPrice = MyResults.lastInforCSDPricing ?? 0;
          MyNewItem.inforCSD_StockInfo_NetQuantityAvailable = MyResults.inforCSD_StockInfo_NetQuantityAvailable ?? 0;
        }

        if (MyProductCustomer)
          MyNewItem.customerProductNumber = MyProductCustomer.customerProductItemNumber;

        //on pousse le produit recalculé dans la liste ici
        productItems.push(MyNewItem);
      }

      //On stock les valeurs de la liste dans dans le local storage
      this.setCartItems(productItems);

      //on retourne la liste
      return of(productItems)
    }));

  }

  public getProductItemsPrice(listOfItemsIds: number[]): Observable<any> {
    return this.customerDefaultShipToId.pipe(mergeMap((shiptoId) => {
      return this.currentCustomerId.pipe(mergeMap((customerId) => {
        let defaultShipToId = shiptoId;

        if (this.permissionService.forceUserGroupRestrictionsDefaultShipId()) {
          defaultShipToId = this.userGroupRestrictions.getValue().defaultShipToId!;
        }

        //failsafe pour ne pas faire de call inutile
        if (customerId === 0 || defaultShipToId === '') {
          return of();
        }

        return this.getItemsCustomerPrices(customerId, defaultShipToId, listOfItemsIds);
      }));
    }));
  }

  private getItemsCustomerPrices(customerId: number, defaultShipToId: string, itemIds: number[]): Observable<any[]> {
    let uri = `${this.BASE_URL}Product/Catalog/GetProductItemPriceForCustomerv2`;
    uri += `/${customerId}`;
    uri += `/${defaultShipToId}`;

    return this.http.post<any>(uri, itemIds).pipe(
      map((reponse: any) => Object.values(reponse))
    );
  }


  //SECTION CART CRUD methods
  /**
   * Ajoute conditionnellement au panier dépdendemment si on respecte les conditions
   */
  public addToCart(productId: number, itemId: number, quantity: number, price: number, note01: string, isProductOnRequest: boolean): void {
    let newCart: CartLine[] = this.cart.getValue();
    let oldLength = newCart.length

    this.canProductBeAddedToCard(productId, itemId, quantity, price, isProductOnRequest).subscribe(canIt => {

      //check si l'item peut être rajouté au cart, sinon on lance une alerte
      if (canIt) {

        // créer un index
        var index = Date.now()

        // on ajoute la nouvelle ligne
        newCart.push(new CartLine(index, productId, itemId, quantity, note01, isProductOnRequest))

        //On met à jour le cart
        this.setCart(newCart)

        // forcer l'attente de la mise à jour du behavior subject
        // avant cela causait des soucis avec le rafraichissement du solde dans la navbar
        if (oldLength < newCart.length) {
          this.getProductList().subscribe(ProductItems => {
            let x = ProductItems
          })
        }
        this.notifySucces();
      }
    })
  }
  /**
   *  IMPORTANT: doit être en théorie uniquement utilisé pour les superviseur
   * @param productId
   * @param itemId
   * @param quantity
   */
  public addToCartWithoutRestriction(productId: number, itemId: number, quantity: number, note01: string, isProductOnRequest: boolean): void {
    let newCart: CartLine[] = this.cart.getValue();

    // créer un index
    var index = Date.now()

    // on ajoute la nouvelle ligne
    newCart.push(new CartLine(index, productId, itemId, quantity, note01, isProductOnRequest))

    this.setCart(newCart)
    this.notifySucces()
  }

  /**
   * Ne doit être appeler que par les input de quantité du cart
   */
  public updateCartItem(index: number, itemId: number, newQuantity: any, price: number, note01: any, isProductOnRequest: boolean): void {
    let _cart = this.cart.getValue();
    let item = _cart.filter((x: CartLine) => x.index === index)[0];
    let quantityToAdd = newQuantity - item.quantity;

    this.canProductBeAddedToCard(item.productId, itemId, quantityToAdd, price, isProductOnRequest)
      .subscribe(canBeAdded => {
        if (canBeAdded) {
          let index = _cart.indexOf(item)
          if (newQuantity != null)
            item.quantity = newQuantity;
          if (note01 != null)
            item.note01 = note01;
          _cart[index] = item
          this.setCart(_cart)
        }
      })
  }
  /**
    * Cette méthode est utilse que pour les input de type number dans le cart
    * 2023-08-01 : cette fonction n'est pas utilisée
   * @param itemId
   * @param newQuantity

    public updateCartItemWithoutRestriction(itemId:number, newQuantity:number):void {
      if(this.permissionService.canAddToCartWithoutRestriction()) {
        let _cart = this.cart.getValue();
        let item = _cart.filter((x:CartLine) => x.itemId === itemId)[0];
        let index = _cart.indexOf(item)

        item.quantity = newQuantity
        _cart[index] = item

        this.setCart(_cart)
      }
    }
    */

  /**
   * @param itemId
   */
  public removeCartItem(index: number): void {
    let _cart = this.cart.getValue()
    this.setCart(_cart.filter(x => x.index !== index))
  }

  private setCart(cart: CartLine[]): void {
    this.cart.next(cart);
    localStorage.setItem(LocalStorage.CART, JSON.stringify(cart))
  }

  public clearCart(): void {
    this.cart.next([]);
    localStorage.removeItem(LocalStorage.CART)
  }

  public setUserGroupRestrictions(ugr: UserGroup): void {
    this.userGroupRestrictions.next(ugr);
    localStorage.setItem(LocalStorage.USERGROUP_RESTRICTIONS, JSON.stringify(ugr))
  }

  public clearUserGroupRestrictions(): void {
    this.userGroupRestrictions.next(new UserGroup())
    localStorage.removeItem(LocalStorage.USERGROUP_RESTRICTIONS)
  }

  public setProductRestrictions(productRestrictions: ProductRestrictions[]): void {
    this.productRestrictions.next(productRestrictions)
    localStorage.setItem(LocalStorage.PRODUCT_RESTRICTIONS, JSON.stringify(productRestrictions))
  }

  public clearProductRestrictions(): void {
    this.productRestrictions.next([])
    localStorage.removeItem(LocalStorage.PRODUCT_RESTRICTIONS)
  }

  public setUserOrderOverview(userOrderOverview: UserOrderOverview): void {
    this.userOrderOverview.next(userOrderOverview)
    localStorage.setItem(LocalStorage.USER_ORDER_OVERVIEW, JSON.stringify(userOrderOverview))
  }

  public clearUserOrderOverview(): void {
    this.userOrderOverview.next(new UserOrderOverview(-1, -1, -1, new Date(), -1, -1, -1, -1, {}))
    localStorage.removeItem(LocalStorage.USER_ORDER_OVERVIEW)
  }

  public setUserOverride(userOverride: UserOverride): void {
    this.userOverride.next(userOverride)
    localStorage.setItem(LocalStorage.USER_OVERRIDE, JSON.stringify(userOverride))
  }

  public clearUserOverride(): void {
    this.userOverride.next(new UserOverride(null, null, null))
    localStorage.removeItem(LocalStorage.USER_OVERRIDE)
  }

  public setCustomerShipToId(shipToId: string): void {
    this.customerDefaultShipToId.next(shipToId)
    localStorage.setItem(LocalStorage.CUSTOMER_SHIPTO_ID, JSON.stringify(shipToId))
  }

  public clearCustomerShipToId(): void {
    this.customerDefaultShipToId.next('-1')
    localStorage.removeItem(LocalStorage.CUSTOMER_SHIPTO_ID)
  }

  public setCurrentCustomerId(customerId: number): void {
    this.currentCustomerId.next(customerId)
    localStorage.setItem(LocalStorage.CURRENT_CUSTOMER_ID, JSON.stringify(customerId))
  }

  public clearCurrentCustomerId(): void {
    this.currentCustomerId.next(0)
    localStorage.removeItem(LocalStorage.CURRENT_CUSTOMER_ID)
  }

  public setCartItems(productItems: ProductItem[]): void {
    this.cartItems.next(productItems)
    localStorage.setItem(LocalStorage.CART_ITEMS_DETAILS, JSON.stringify(productItems))
  }

  public clearCartItems(): void {
    this.cartItems.next([])
    localStorage.removeItem(LocalStorage.CART_ITEMS_DETAILS)
  }

  public clearGlobalData(): void {
    this.clearCart();
    this.clearProductRestrictions();
    this.clearUserGroupRestrictions();
    this.clearUserOrderOverview();
    this.clearUserOverride();
    this.clearCustomerShipToId();
    this.clearCurrentCustomerId();
    this.clearCartItems();
  }

  //SECTION  Restrictions methods
  /**
   * IMPORTANT: Doit appeler la méthode initLoadCartAndItemsDetails dans le ngOnInit du composant appelant avant de pouvoir utiliser cette méthode.
   * @param itemId
   * @param quantityToAdd Quantité à rajouter au cart
   * @returns une promise booléenne si la modification ou ajout d'item est légale ou non
   */
  private canProductBeAddedToCard(productId: number, itemId: number, quantityToAdd: number, itemPrice: number, isProductOnRequest: boolean): Observable<boolean> {
    return this.getRemainingBudget().pipe(mergeMap((remainingBudget: number) => {

      // Les superviseur ont toujours le droits d'ajouter à leur guise.
      if (this.permissionService.canAddToCartWithoutRestriction()) {
        return of(true)
      }

      let remainingQuantity = this.getRemainingQuantity(productId)
      let productRes: ProductRestrictions = this.productRestrictions.getValue().filter(x => x.productId === productId)[0];

      // check si on dépasse le budget restant
      let checkBudget = remainingBudget - (itemPrice * quantityToAdd)
      if (checkBudget < 0) {
        this.popupAlertOverMaxBudget(-checkBudget)
        return of(false)
      }

      // si aucune restriction de QTÉ n'a été placée sur le produit ou le regroupement OU si un produit à la demande
      if (productRes.maxQuantity == null || isProductOnRequest) {
        return of(true);
      }

      // Validation de la quantité restante
      if (quantityToAdd <= remainingQuantity) {
        return of(true);
      }
      else {

        if (productRes.productGroupingName == null || productRes.productGroupingName === '') {
          this.popupAlertOverMaxProductQuantity(remainingQuantity)
        }
        else {
          this.popupAlertOverMaxProductGroupQuantity(remainingQuantity)
        }

        return of(false);
      }
    }))
  }

  private getQuantityAlreadyOrded(productId: number): number {
    let uoo: object = this.userOrderOverview.getValue().qtyConsumedByProductId
    let uoo_keys = Object.keys(uoo)
    let uoo_values = Object.values(uoo)
    let value = uoo_values[uoo_keys.indexOf(`${productId}`)]

    return value ? value : 0
  }

  /**
   *
   * @returns Le budget restant
  */
  public getRemainingBudget(): Observable<number> {
    //Fix pour éviter que le header envoit des erreurs de itemprice is undefined lorsuq'on fait un ngDoCheck
    if (!this.permissionService.canSeeBudget()) {
      this.budgetAmountLimitationActivated.next(false);
      return of(Infinity) //hey oui infinity est un nombre légal en Javascript qui l'aurait cru
    }

    let ugr = this.userGroupRestrictions.getValue()
    let uo = this.userOverride.getValue()

    // Si les users n'ont pas de restrictions budgétaires alors on retourne infini
    if (ugr.maximumAllowedAmount == null && uo.overrideEmployeMaximumAllowedAmount == null) {
      this.budgetAmountLimitationActivated.next(false);
      return of(Infinity)
    }

    let remainingBudget: number = this.userOrderOverview.getValue().amountRemaining
    let _cart: CartLine[] = this.cart.getValue()
    return this.cartItems.pipe(
      // take(1) c'est pour éviter que le système tourne en boucle lorsqu'on ajoute un item au panier
      // cela force un seul retour et sa unsubscribe après.
      take(1),
      mergeMap(cartItems => {
        try {
          for (const line of _cart) {
            let itemDetails: ProductItem = cartItems.filter(x => x.productItemId === line.itemId)[0];
            remainingBudget = remainingBudget - (itemDetails.itemPrice * line.quantity)
          }
        }
        // Fail safe on retourne 0 pour bloquer le reste du système
        catch (error) {
          this.budgetAmountLimitationActivated.next(true);
          return of(0)
        }

        this.budgetAmountLimitationActivated.next(true);
        return of(remainingBudget ? remainingBudget : 0);
      }
      ))
  }

  /**
   *
   * @returns La valeur du panier
  */
  public getCartTotalValue(): Observable<number> {
    let value = 0;
    return this.cart.pipe(mergeMap(_cart => {
      return this.cartItems.pipe(mergeMap(cartItems => {
        for (const cartLine of _cart) {
          try {

            value += (cartLine.quantity * cartItems.filter(x => x.productItemId === cartLine.itemId)[0].itemPrice)

          } catch (error) {
            //on ne fais rien à cause que le header componnent trigger trop souvent et envoi trop d'erreur dans la console
          }
        }
        return of(value)
      }))
    }))

  }


  public getListOfAllPossibleFilteringProperties(): string[] {
    return ['productItemColor',
      'productItemFootwearProtectionSize',
      'productItemFootwearProtectionWidth',
      'productItemProtectiveClothingSize',
      'productItemHandProtectionSize',
      'productItemHeadProtectionSize',
      'productItemHearingProtectionSize',
      'productItemFallProtectionSize',
      'productIteminstrumentationSize',
      'productItemErgonomicSize',
      'productItemRespiratoryProtectionSize',
      'productItemLockoutDiameter',
      'productItemDiameter',
      'productItemFirstAidSize',
      'productItemProtectiveClothingLegLength',
      'productItemFallProtectionLength',
      'productItemLensColor',
      'productIteminstrumentationLength',
      'productItemHand',
      'productItemFirstAidFormat',
      'productItemEnvironmentFormat',
      'productIteminstrumentationFormat',
      'productItemEyewearFormat',
      'productItemQtyPerBox',
      'productItemHearingQtyPerBox',
      'productItemDiopter',
      'productItemPressure',
      'productItemFrameColor',
      'productItemThickness',
      'productItemManagementAndStorageOfHazardousDimensions',
      'productItemFirstAidDimensions',
      'productItemEyewearDimension',
      'productItemFootwearLength',
      'productItemHandProtectionLength',
      'productItemSafetyEyewearSize',
      'productItemLadderHeight',
      'productItemFireExtinguishersCapacity',
      'productItemFireExtinguishersUlcRating',
      'productItemPoweredAirAndSuppliedAirLength',
      'productItemClothingLength',
      'productItemWeightCapacity',
      'productItemAchorageType',
      'productItemLifelineHook',
      'productItemFallProtectionDimensions',
      'productItemLanguage',
      'productItemSignsAndDisplayMaterial',
      'productItemSignsAndDisplayDimension',
      'productItemHeadband',
      'productItemLengthFeet',
      'productItemFireWidthin',
      'productItemAtpv',
      'productItemBracketType',
      'productItemCollar',
      'productItemTrafficConeSize',
      'productItemSizeinitialimport'
    ]
  }

  /**
   *
   * @param productId
   * @returns la quantité restante du produit ou de son groupe s'il en possède un.
  */
  public getRemainingQuantity(productId: number): number {

    let _productRestrictions = this.productRestrictions.getValue();
    let _cart = this.cart.getValue();

    let productGroupingName: string | null = null;
    let filteredRestriction = _productRestrictions.filter(x => x.productId === productId);

    // Failsafe, si les données globales sont mal chargées alors on retourne 0
    if (filteredRestriction.length === 0) {
      return 0;
    }

    productGroupingName = filteredRestriction[0].productGroupingName;

    let quantityAlreadyAdded: number = 0;
    let filteredProductRes = _productRestrictions.filter(y => y.productGroupingName === productGroupingName)
    let quantityAlreadyOrded = 0;

    if (productGroupingName == null || productGroupingName === '') {
      //On va rajouter les quanitité des items dans le cart
      for (const line of _cart.filter(y => y.productId === productId)) {
        quantityAlreadyAdded += line.quantity
      }
      //On rajoute les quantités déjà commandées
      quantityAlreadyOrded = this.getQuantityAlreadyOrded(productId);
    }
    //Cela va chercher les quantités des autres produit ayant le même grouping product name
    else {
      for (const res of filteredProductRes) {
        //On va rajouter les quanitité des items dans le cart ayant le même product grouping name
        for (const line of _cart.filter(y => y.productId === res.productId)) {
          quantityAlreadyAdded += line.quantity
        }
        //On rajoute les quantités déjà commandées des autres produits du même product grouping name
        quantityAlreadyOrded += this.getQuantityAlreadyOrded(res.productId)
      }
    }

    let maxQuantity = filteredRestriction[0].maxQuantity

    //si aucune restriction n'est imposéee
    if (maxQuantity == null) {
      return Infinity
    }
    else {
      let remainingQuantity: number = maxQuantity - quantityAlreadyAdded - quantityAlreadyOrded

      if (remainingQuantity < 0) {
        remainingQuantity = 0
      }

      return remainingQuantity
    }
  }

  public canUserSeeProductPrice(): Observable<boolean> {
    // Si c'est un employé normale, alors on doit check dans les restrictions de son user group
    if (this.permissionService.mustApplyUserGroupRestriction()) {
      return this.userGroupRestrictions.pipe(
        mergeMap((ugr: UserGroup) => {
          return of(!ugr.mustHidePrices)
        })
      )
    }
    //Les superviseurs peuvent toujours voire les prix
    else {
      return of(true)
    }
  }

  /**
   *
   * @returns Booléen de si on peut voir la bannière dans le header du solde restant
   */
  public canUserSeeHeaderBudget(): Observable<boolean> {
    if (this.permissionService.canSeeBudget()) {
      return this.canUserSeeProductPrice()
    }
    //On ne veut pas afficher le solde restant aux non client_employé,
    //puisqu'ils n'ont pas de solde
    else {
      return of(false)
    }
  }

  private popupAlertOverMaxBudget(overBudget: number): void {
    let title = this.utilService.getPropertyTranslated("ATTENTION - Vous avez dépassé votre budget", "WARNING - You have exceeded your budget");
    let msg = this.utilService.getPropertyTranslated(`Vous avez dépassé votre budget. \n Dépassement de la marge: ${overBudget.toFixed(2)} $`, `You went over your allowed budget. \n You're $${overBudget.toFixed(2)} over budget`);
    this.utilService.alert(title, msg);
  }

  private popupAlertOverMaxProductGroupQuantity(remainingQuantity: number): void {
    let title = this.utilService.getPropertyTranslated("ATTENTION - Vous avez dépassé votre limite", "WARNING - You have exceeded your limit");
    let msg = this.utilService.getPropertyTranslated(`Vous avez dépassé la quantité maximale allouée pour ce groupe de produits, la quantité restante est de ${remainingQuantity}.`,
      `You went over the maximum quantity allowed for this group of items, the remaining quantity is ${remainingQuantity}.`);
    this.utilService.alert(title, msg);
  }

  private popupAlertOverMaxProductQuantity(remainingQuantity: number): void {
    let title = this.utilService.getPropertyTranslated("ATTENTION - Vous avez dépassé votre limite", "WARNING - You have exceeded your limit");
    let msg = this.utilService.getPropertyTranslated(`Vous avez dépassé la quantité maximale allouée pour ce produit, la quantité restante est de ${remainingQuantity}.`,
      `You went over the maximum quantity allowed for this item, the remaining quantity is ${remainingQuantity}.`);
    this.utilService.alert(title, msg);
  }

  private notifySucces(): void {
    this.utilService.notify(
      'shared.notification.success_product_added',
      'success'
    );
  }

  //TEMPORAIRE! 2023-08-18 - fait en PROD HotFix - à remplacer par un test similaire, mais avec la nouvelle méthjode, dans le PermissionService...
  public canUserSeeCartGlobalNote(): boolean {
    return true // 2023-09-06 : À La demande de Marie, on doit réactiver la note du panier pour les clients employés.

  }



  /** Permet de faire un login et de rafraichir les données globales
   *
  */
  loginAndRefreshData(username: string, password: string): Observable<any> {
    //On doit d'abord faire un login
    return this.authService.login(username, password)
      .pipe(
        //On doit ensuite rafraichir les données globales
        switchMap((x) => {
          return this.refreshGlobalUserData(x.userId);
        }),
        tap(() => {
          //si on veut faire quelquechose apres tout ça [attention!  un bon délais à cause de RefreshGlobalUserData!]
        }),
      );
  }


  /** Permet de DÉBUTER l'impersonation et de rafraichir les données globales
   *
   */
  ImpersonateAndRefreshData(UserIdToImpersonate: string): Observable<any> {
    //On doit d'abord faire un 'impersonate' (donc on prend l'identitié d'un usager passé en param)
    return this.authService.ImpersonateUser(UserIdToImpersonate)
      .pipe(
        switchMap((x) => {
          //On doit ensuite rafraichir les données globales
          return this.refreshGlobalUserData(x.userId);
        }),
        tap(() => {
          //si on veut faire quelquechose apres tout ça [attention!  un bon délais à cause de RefreshGlobalUserData!]
        }),
      );
  }


  /** Permet de CESSER l'impersonation et de rafraichir les données globales
   *
   */
  StopImpersonationAndRefreshData(): Observable<any> {
    //On doit d'abord stopper l'impersonation
    return this.authService.StopImpersonation()
      .pipe(
        switchMap((x) => {
          //On doit ensuite rafraichir les données globales
          return this.refreshGlobalUserData(x.userId);
        }),
        tap(() => {
          //si on veut faire quelquechose apres tout ça [attention!  un bon délais à cause de RefreshGlobalUserData!]
        }),
      );
  }


  /** Permet de faire un refresh des données globales (CVL, donnée du user connecté, liste des )
   *
   */
  public refreshGlobalUserData(newUserId: string): Observable<any> {
    return this.userService.read(newUserId)
      .pipe(
        tap((x) => {
          this.authService.addUserNames(x);
        }),
        switchMap(() => {
          return this.loadGlobalData();
        }),
        switchMap(() => {
          return this.pimService.initLoadCvlFields();;
        }),
        switchMap(() => {
          return this.pimService.initLoadCvlData();
        }),
      );
  }

}
