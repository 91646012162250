import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { LocalStorage } from 'src/app/core/local-storage.enum';
import { permissionCodeEnum } from '../permission-code.enum';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AuthUser } from './auth-user.model';
import { User } from 'src/app/module/user/user.model';

const apiUrl = environment.apiUrl;

export interface UserLoginModel {
  accessToken: string;
  refreshToken: string;
  userId: string;

}

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  //contient les informations de l'usager actuellement connecté
  private _currentAuthUser : AuthUser = new AuthUser();
  currentAuthUserBehavior : BehaviorSubject<AuthUser> = new BehaviorSubject<AuthUser>(this._currentAuthUser);

  public GetCurrentUser() : AuthUser {
    return this._currentAuthUser;
  }

  constructor(private http: HttpClient,
              private router: Router,
     ) {}

  login(username: string, password: string): Observable<UserLoginModel> {
    const myUri = `${apiUrl}security/Auth/Login`;
    var myTask = this.http.post<UserLoginModel>(myUri, {
      username: username,
      password: password,
    });
    return this.processLogin(myTask);
    }

  loginSSO(token: string) {
    const myUri = `${apiUrl}security/Auth/LoginWithMicrosoftSSO?token=${token}`;
    var myTask = this.http.post<UserLoginModel>(myUri, {});
    return this.processLogin(myTask);
  }

  /**
   * Cette méthode permet de traiter le retour de l'API de login, et de faire les actions nécessaires.
   **/
  processLogin(myTask: any) {
    myTask = myTask.pipe(
      tap((myLoginReturnInfo: UserLoginModel) => {
        // decode
        const helper = new JwtHelperService(myLoginReturnInfo.accessToken);

        // set localstorage keys
        localStorage.setItem(LocalStorage.JWT, myLoginReturnInfo.accessToken); // on stocke le JWT dans la mémoire du navigateur
        localStorage.setItem(LocalStorage.JWT_REFRESH_TOKEN,myLoginReturnInfo.refreshToken);
        localStorage.setItem(LocalStorage.JWT_SOURCE_SERVER,environment.apiUrl);

        // Demande de Félix, on vide le panier au Login
        localStorage.removeItem(LocalStorage.CART)
        localStorage.removeItem(LocalStorage.USER_FULL_NAME);

        this.hydrate();

        // rediriger vers le path mis en mémoire OU vers la page d'accueil
        let redirectUrl = localStorage.getItem(LocalStorage.REDIRECT_URL);
        if (redirectUrl != null && redirectUrl != 'undefined' && redirectUrl != 'null') {
          localStorage.removeItem(LocalStorage.REDIRECT_URL);
          this.router.navigate([redirectUrl]);
        }
        else {
          this.router.navigate(['/auth/home']);
        }

        return myLoginReturnInfo;
      }),
      catchError((error: any) => {
        // handle error here
        console.error('An error occurred:', error);
        throw error;
      })
    );
    return myTask;
  }
  
  logout(redirect: boolean) {
    // remove localstorage keys
    localStorage.removeItem(LocalStorage.JWT);
    localStorage.removeItem(LocalStorage.JWT_REFRESH_TOKEN);
    localStorage.removeItem(LocalStorage.JWT_SOURCE_SERVER);
    localStorage.removeItem(LocalStorage.JWT_IMPERSONATOR);

    // remove user info
    localStorage.removeItem(LocalStorage.USER_FIRST_NAME);
    localStorage.removeItem(LocalStorage.USER_LAST_NAME);
    localStorage.removeItem(LocalStorage.USER_EMPLOYEE_PERSONNAL_NOTES);
    // reset
    this.resetCurrentUserData(true);

    // storer le url dans le localstorage pour le rediriger après le login   
    let path = window.location.pathname;
    if (path != '/' && path.substring(0,5) != '/auth') {
      localStorage.setItem(LocalStorage.REDIRECT_URL, path);
    }

    // redirect
    if (redirect)
      this.router.navigate(['/auth']);
    // call api logout
    return this.http.post(apiUrl + 'security/Auth/logout', {}).pipe(
      tap((resData) => {})
    );
  }

  /**
   * Cette fonction permet de rafraichir les données de l'usager en cours, à partir du JWT stocké dans le localStorage.  Et autre info
   * 
   */
  hydrate(): void {

    // decode token
    const helper = new JwtHelperService();
    var jwt = localStorage.getItem(LocalStorage.JWT) as string;
    this._currentAuthUser = new AuthUser();

    try {

      const decodedToken = helper.decodeToken(jwt) as any;

    if (decodedToken) {
      // assign
      this._currentAuthUser.userId = decodedToken['UserID'];
      this._currentAuthUser.isCurrentlyLogged = true;


      //************************************
      //Liste des privilèges de mon usager - transférer dans une variable globalement accessible.  le claim devra toujours etre en dictionnaire
      for (const claim in decodedToken) {
        if (claim.startsWith('PRIVILEGE_')) {
          let myResult = decodedToken[claim];
          let myClaim : string[];

          //on s'assure que le claim value est un array de string, sinon on le forcera (.net core ne retourne pas toujours un array)
          if (Array.isArray(myResult))
            myClaim = myResult;
          else
            myClaim = [myResult];

          //on va ajouter le claim dans le dictionnaire, sinon on le concatemne si existe deja
          if (this._currentAuthUser.listPrivilegeByClaims[claim] == null)
            this._currentAuthUser.listPrivilegeByClaims[claim] = myClaim;
          else
            this._currentAuthUser.listPrivilegeByClaims[claim] = this._currentAuthUser.listPrivilegeByClaims[claim].concat(myClaim);

        }
      }
      //************************************

      //************************************
      //retro-compatibilité : on va feeder la liste des userGroup et des customers, pour les catalogues..
      let claims : string[] = this._currentAuthUser.listPrivilegeByClaims[permissionCodeEnum.PRIVILEGE_ORDER_CANBROWSECATALOG];

      // tmp is not an array
      if (claims != null && claims.length > 0) {
        const custIds = new Set<number>();
        const UserGroupIds = new Set<number>();
        claims.forEach((claim: string) => {
          let match = claim.match(/CUSTID:(\d+)/);
          if (match && match.length > 1){
            let matchValue = match[1];
            custIds.add(+matchValue);
          }
          match = claim.match(/UGID:(\d+)/);
          if (match && match.length > 1){
              let matchValue = match[1];
              UserGroupIds.add(+matchValue);
          }
        });
        this._currentAuthUser.ListCatalogEnabledCustomerIds = Array.from(custIds);
        if (this._currentAuthUser.ListCatalogEnabledCustomerIds.length >= 1)
          this._currentAuthUser.currentCustomerId = this._currentAuthUser.ListCatalogEnabledCustomerIds[0];

        this._currentAuthUser.userGroupHeaderIds = Array.from(UserGroupIds);
        if (this._currentAuthUser.userGroupHeaderIds.length >= 1)
          this._currentAuthUser.currentUserGroupHeaderId = this._currentAuthUser.userGroupHeaderIds[0];
      }
      //************************************

      this._currentAuthUser.userName = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];

      //on va chercher les nom/prenom et les notes de l'usager dans le localStorage
      let firstName = localStorage.getItem(LocalStorage.USER_FIRST_NAME) ?? "";
      let lastName = localStorage.getItem(LocalStorage.USER_LAST_NAME) ?? "";
      let employeePersonnalComment = localStorage.getItem(LocalStorage.USER_EMPLOYEE_PERSONNAL_NOTES) ?? "";
  
      this._currentAuthUser.firstName = firstName;
      this._currentAuthUser.lastName = lastName;
      this._currentAuthUser.employeePersonnalComment = employeePersonnalComment;

      let Test = decodedToken['IsEmployee'];
      if (Test != null && Test === 'FALSE')
        this._currentAuthUser.currentUserIdClientEmploye = false;
    }
    } catch (error) {
      this.logout(true);
    }

    this.notifyAuthUserChange('hydrate');

  }

  /** Cette méthode permet de notifier tout les subscribers que l'usager a changé.
   *
   * @param sourceNotif
   */
  private notifyAuthUserChange(sourceNotif : string) {
      //la partie ici, permet de NOTIFIER tout les subscribers que l'usager a changé
      this.currentAuthUserBehavior.next(this._currentAuthUser);
  }

  /** Cette méthode permet de réinitialiser l'usager en cours.  C'est utile lorsqu'on veut se déconnecter, ou lorsqu'on veut se faire passer pour un autre usager.
   *
   * @param mustNotify
   */
  resetCurrentUserData(mustNotify: boolean = true) {
    this._currentAuthUser = new AuthUser();
    if (mustNotify)
      this.notifyAuthUserChange('reset');
  }


  checkIfLoginExpired(): boolean {
    const helper = new JwtHelperService();
    var jwt = localStorage.getItem(LocalStorage.JWT) as string;
    if (helper.isTokenExpired(jwt)) {
      return true;
    }
    return false;
  }

  checkIfSameEnvironment(): boolean {
    return (
      localStorage.getItem(LocalStorage.JWT_SOURCE_SERVER) === environment.apiUrl
    );
  }

  /**
   * Cette méthode permet de revenir à l'usager original.  le JWT sera changé pour celui de l'usager original.
   */
  public StopImpersonation(): Observable<UserLoginModel> {
    var jwt = localStorage.getItem(LocalStorage.JWT_IMPERSONATOR) as string;
    localStorage.setItem(LocalStorage.JWT, jwt); // on stocke le JWT dans la mémoire du navigateur
    localStorage.removeItem(LocalStorage.JWT_IMPERSONATOR);

    // hydrate & redirect
    this.hydrate();

    return of();
    //return of(this._currentAuthUser);

  }

  /**
   * Cette méthode permet de se faire passer pour un autre usager.  le JWT sera changé pour celui de l'usager à impersonner.
   * @param UserIdToImpersonate
   */
  public ImpersonateUser(UserIdToImpersonate: string) {

    var jwt = localStorage.getItem(LocalStorage.JWT) as string;
    localStorage.setItem(LocalStorage.JWT_IMPERSONATOR, jwt); // on stocke le JWT dans la mémoire du navigateur
    let strUri = `${apiUrl}security/Auth/ImpersonateUser?UserIdToImpersonate=${UserIdToImpersonate}`

    return this.http.post<UserLoginModel>(strUri, {}).pipe(
      tap((resData) => {

        this.resetCurrentUserData();
        // decode
        const helper = new JwtHelperService(resData.accessToken);
          // set localstorage keys
          localStorage.setItem(LocalStorage.JWT, resData.accessToken); // on stocke le JWT dans la mémoire du navigateur
          localStorage.setItem(LocalStorage.JWT_REFRESH_TOKEN,resData.refreshToken);
          localStorage.setItem(LocalStorage.JWT_SOURCE_SERVER,environment.apiUrl);

          // Demande de Félix, on vide le panier au Login
          localStorage.removeItem(LocalStorage.CART)

          // hydrate & redirect
          this.hydrate();

      })
    );
  }

  public isCurrentlyImpersonating():boolean {
    const jwtImpersonator = localStorage.getItem(LocalStorage.JWT_IMPERSONATOR);
    return jwtImpersonator != null && jwtImpersonator !== '' && jwtImpersonator !== 'JWT_IMPERSONATOR';
  }

  /**  Permet d'ajouter nom/prenom et autre info à l'usager en cours.
   * ATTENTION!  Ne pas écraser des valeurs comme le userId, IsLogged, les claims, etc..   C'est juste pour ajouter des infos supplémentaires
   * @param User
   */
  public addUserNames(User:User) : void {
    this._currentAuthUser.userName = User.userName;
    this._currentAuthUser.firstName = User.userFirstName;
    this._currentAuthUser.lastName = User.userLastName;
    this._currentAuthUser.fullName = User.userFullName;
    this._currentAuthUser.employeePersonnalComment = User.employeePersonnalComment;

    // on les stocke dans le localStorage
    localStorage.setItem(LocalStorage.USER_FULL_NAME, User.userFullName);
    localStorage.setItem(LocalStorage.USER_FIRST_NAME, User.userFirstName);
    localStorage.setItem(LocalStorage.USER_LAST_NAME, User.userLastName);
    localStorage.setItem(LocalStorage.USER_EMPLOYEE_PERSONNAL_NOTES, User.employeePersonnalComment)

    // On update pour notifier les subscribers
    this.notifyAuthUserChange('addUserNames');
  }


  /** Cette méthode permet de RAFRAICHIR le AccessToken, grace au REFRESH token
   * on peut ne PAS
   * @param myUserLoginModel permet de passer un model de login FORCÉE.
   * LAISSER VIDE pour prendre lui par défaut
   * @returns TRUE si ca a marché, FALSE sinon
  */
  public refreshAuthTokens(myUserLoginModel:UserLoginModel|undefined=undefined) : Observable<UserLoginModel> {

    //**************************
    //#region STEP1 - Préparation du payload à pousser pour rafraichir le token
    if (myUserLoginModel == null)
    {
      //on fetch du LocalStorage
      let accessToken = localStorage.getItem(LocalStorage.JWT) as string;
      let refreshToken  = localStorage.getItem(LocalStorage.JWT_REFRESH_TOKEN) as string;

      //on refait le payload
      myUserLoginModel = {
        accessToken : accessToken,
        refreshToken :refreshToken,

      } as UserLoginModel;
    }
    //si l'usager à passé un payload,  ca pourrait être pour rafraichir le token d'une session en impersonate (par exemple).
    else{
      //NE PAS IMPLÉMENTER POUR L'INSTANT
    }
    //#endregion
    //**************************

    let strUri = apiUrl + 'security/Auth/RefreshToken'

    //**************************
    //#region STEP2 - Appel à l'API et traitement du retour
    return this.http.post<UserLoginModel>(strUri, myUserLoginModel).pipe(
      tap((resData) => {
          // decode
          const helper = new JwtHelperService(resData.accessToken);

          // set localstorage keys
          localStorage.setItem(LocalStorage.JWT, resData.accessToken); // on stocke le JWT dans la mémoire du navigateur
          localStorage.setItem(LocalStorage.JWT_REFRESH_TOKEN,resData.refreshToken);

        // hydrate & redirect
        this.hydrate();
      })
    );
    //#endregion
    //**************************

  }
}
