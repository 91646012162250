import { Component } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import * as svgIcons from '@progress/kendo-svg-icons';

@Component({
  selector: 'app-pop-password',
  templateUrl: './pop-password.component.html',
  styleUrls: ['./pop-password.component.scss'],
})
export class PopPasswordComponent {
  public allIcons = svgIcons;

  confirmPassword = '';
  newPassword = '';
  oldPassword = '';
  modifySelf = false;

  errorKey!: string;

  constructor(public dialog: DialogRef) {}

  close() {
    this.dialog.close();
  }

  changePassword() {
    this.errorKey = '';
    if (this.modifySelf && this.oldPassword == '')
      this.errorKey = 'module.auth.reset-password.error_confirm_old_password';
    else if (this.newPassword.length < 8)
      this.errorKey = 'module.auth.reset-password.error_too_short';
    else if (this.newPassword != this.confirmPassword)
      this.errorKey = 'module.auth.reset-password.error_not_the_same';
    else if (/[A-Z]/.test(this.newPassword) == false)
      this.errorKey = 'module.auth.reset-password.error_uppercase';
    else if (/[a-z]/.test(this.newPassword) == false)
      this.errorKey = 'module.auth.reset-password.error_lowercase';
    else if (/[0-9]/.test(this.newPassword) == false)
      this.errorKey = 'module.auth.reset-password.error_number';
    else if (
      /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(this.newPassword) == false
    )
      this.errorKey = 'module.auth.reset-password.error_special_char';
    else this.dialog.close(true);
  }
}
