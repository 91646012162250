import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { PermissionService } from 'src/app/core/permission.service';
import { CustomerService } from 'src/app/module/customer/customer.service';
import { TranslationManagementService } from 'src/app/core/translation/translate.service';
import { UserService } from 'src/app/module/user/user.service';
import { GlobalDataService } from 'src/app/core/global-data/global-data.service';
import { Customer } from 'src/app/module/customer/customer.model';
import { Subject, Subscription, take, takeUntil, tap } from 'rxjs';
import { LocalStorage } from 'src/app/core/local-storage.enum';
import { AuthUser } from 'src/app/core/auth/auth-user.model';
import { UtilService } from 'src/app/shared/util.service';
import { permissionCodeEnum } from 'src/app/core/permission-code.enum';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})

export class SidenavComponent implements OnInit, OnDestroy {
  @Input() expanded: boolean;

  private unsubNotifier = new Subject<void>();

  locale:string = 'fr'
  customerInfo = new Customer()
  securityExpanded: boolean = false;
  systemExpanded:boolean = false;

  canSeeSidenavUserInfo = false;
  canSeeSidenavCustomer = false;
  canSeeSidenavUserGroup = false;
  canSeeSidenavReport = false;
  canSeeSidenavOrder = false;
  canSeeSidenavUser = false;
  canSeeSidenavUserRight = false;
  canSeeSidenavAuditLogs = false;
  canSeeSidenavProduct = false;
  canSeeSidenavCatalog = false;

  canSeeSystemSideNav: boolean = false;

  constructor(
    public authService: AuthService,
    public permissionService: PermissionService,
    public customerService: CustomerService,
    private translateService: TranslationManagementService,
    private utilService: UtilService,
    private globalDataService: GlobalDataService
  ) {
    this.expanded = true;
    //LOG_BASE_LifeCycle - this.utilService.logInformation(`SidenavComponent->constructor`);
  }

  ngOnInit() {
    //LOG_BASE_LifeCycle - this.utilService.logInformation(`SidenavComponent->ngOnInit()  -  ${this.currentUser().GetDebugUserInfo()}`);

    // langue
    this.locale = this.translateService.getCurrentLang();

    this.translateService.onLangChange
    //.pipe(takeUntil(this.unsubNotifier))
    .subscribe( langEvent => {
      this.locale = langEvent.lang
    });

    this.refreshSideNavRights();

    //on va ÉCOUTER le changement d'uysager en cours, et déclencher un refresh au besoin.  Le déclenchement se fait dans authService, via une méthode qui fait un next sur le currentAuthUserBehavior
    this.authService.currentAuthUserBehavior
      .pipe(
        tap((x) => {
          this.refreshSideNavRights();
          //LOG_BASE_LifeCycle - this.utilService.logInformation(`SidenavComponent->currentAuthUserBehavior.subscribe   -  ${this.currentUser().GetDebugUserInfo()}`);

        })
        ,takeUntil(this.unsubNotifier)
        )
      .subscribe();

    //on va ÉCOUTER le changement de customer en cours, et déclencher un refresh au besoin.
    this.globalDataService.currentCustomerId
    .pipe(takeUntil(this.unsubNotifier))
    .subscribe( id => {
      if (this.customerInfo.customerId !== id && id !== 0) {
        this.getCustomerInfo(id);
      }
    })


  }

  /** Cette méthode est appelée à chaque fois que l'usager change.  Elle va rafraichir les droits d'accès au menu de gauche
   *
   */
  refreshSideNavRights(){

    let securityExpanded = localStorage.getItem("sidenav_security_expanded");
    this.securityExpanded = securityExpanded == 'true';
    let systemExpanded = localStorage.getItem("sidenav_system_expanded");
    this.systemExpanded = systemExpanded == 'true';

    // permission

    this.canSeeSidenavUserInfo = this.permissionService.canSeeSidenavUserInfo();
    this.canSeeSidenavCustomer = this.permissionService.canSeeSidenavCustomer();
    this.canSeeSidenavUserGroup = this.permissionService.canSeeSidenavUserGroup();
    this.canSeeSidenavReport = this.permissionService.canSeeSidenavReport();
    this.canSeeSidenavOrder = this.permissionService.canSeeSidenavOrder();
    this.canSeeSidenavUser = this.permissionService.canSeeSidenavUser();
    this.canSeeSidenavUserRight = this.permissionService.canEditUserRight();
    this.canSeeSidenavAuditLogs = this.permissionService.canSeeSidenavAuditLogs();
    this.canSeeSidenavProduct = this.permissionService.canSeeSidenavProduct();
    this.canSeeSidenavCatalog = this.permissionService.canSeeSidenavCatalog();
    this.canSeeSystemSideNav = this.permissionService.canSeeSystemSideNav();

  }

  ngOnDestroy(): void {
    this.unsubNotifier.next();
    this.unsubNotifier.unsubscribe();

  }


  private getCustomerInfo(customerId:number) {
    this.customerService.read(customerId)
    .pipe(takeUntil(this.unsubNotifier))
    .subscribe( (customer) => {
      this.customerInfo = customer;
    });
  }

  onSecurityExpandedChange(event: any) {
    localStorage.setItem('sidenav_security_expanded', event)
    this.securityExpanded = !this.securityExpanded;
  }


  onSystemExpandedChange(event: any) {
    localStorage.setItem('sidenav_system_expanded', event)
    this.systemExpanded = !this.systemExpanded;
  }


  /** Cette méthode permet d'aller chercher la version 'live' de l'usager connecté (pas via un subscription).  Pratique pour tester localement 'IsLogged... avoir le nom/prénom, etc,  sans avoir à lier le TemplateHTML à authhService
   * @returns
   */
  public currentUser(): AuthUser {return this.authService.GetCurrentUser()};

}
