import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';

const routes: Routes = [
  { path: '', redirectTo: '/auth/home', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () =>
      import('./module/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'customer',
    loadChildren: () =>
      import('./module/customer/customer.module').then((m) => m.CustomerModule),
  },
  {
    path: 'order',
    loadChildren: () =>
      import('./module/order/order.module').then((m) => m.OrderModule),
  },
  {
    path: 'product',
    loadChildren: () =>
      import('./module/product/product.module').then((m) => m.ProductModule),
  },
  {
    path: 'user',
    loadChildren: () =>
      import('./module/user/user.module').then((m) => m.UserModule),
  },
  {
    path: 'user-group',
    loadChildren: () =>
      import('./module/user-group/user-group.module').then(
        (m) => m.UserGroupModule
      ),
    },
    {
      path: 'catalog',
      loadChildren: () =>
      import('./module/catalog/catalog.module').then((m) => m.CatalogModule),
    },
    {
      path: 'cart',
      loadChildren: () =>
      import('./module/cart/cart.module').then((m) => m.CartModule),
    },
    {
      path: 'checkout',
      loadChildren: () =>
      import('./module/checkout/checkout.module').then((m) => m.CheckoutModule),
    },
    {
      path: 'report',
      loadChildren: () =>
      import('./module/report/report.module').then((m) => m.ReportModule),
    },
    {
      path: 'system',
      loadChildren: () =>
      import('./module/system/system.module').then((m) => m.SystemModule),
    },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes or popups
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled', // Set to enabledBlocking to use Angular Universal
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
