export class AuthUser {
  isCurrentlyLogged: boolean = false;
  //UserId dans Safestock (dans l'API).  c'est un gros string GUID (ex: 6f6e2b7e-7b1e-4b1e-9b1e-4b1e9b1e9b1e)
  userId : string = '';
  //c'est un peu le "code de l'usager".  Dans safestock, ça part de 100,000 et ça monte (donc username=110015)
  userName : string = '';

  //nom et prenom de l'individu connecté
  firstName : string  = '';
  lastName : string = '';
  fullName : string = '';

  //indentifie si l'usaer est ultra limité, étant un client employé,  c'est une simplification des CLAIMS, calculé par L'API...
  currentUserIdClientEmploye : boolean = true;

  //TRÈS IMPORTANT!  Il s'agit ici de l'ensemble des droits de l'usager connecté.  C'est un dictionnaire de clé/valeur.
  listPrivilegeByClaims: Record<string, string[]> = {};
  userGroupHeaderIds: number[] = []
  currentUserGroupHeaderId = 0;

  currentCustomerId: number = 0;
  ListCatalogEnabledCustomerIds: number[] = [0];

  // arh, copie la prop dans User 
  employeePersonnalComment: string = '';

  constructor(
  ) {

  }


  public GetDebugUserInfo() :string {
    return `AuthUser->GetDebugUserInfo() - isCurrentlyLogged:${this.isCurrentlyLogged} - userId:${this.userId} - userName:${this.userName} - firstName:${this.firstName} - lastName:${this.lastName} - fullName:${this.fullName} - currentUserIdClientEmploye:${this.currentUserIdClientEmploye} - currentCustomerId:${this.currentCustomerId} - currentUserGroupHeaderId:${this.currentUserGroupHeaderId} - ListCatalogEnabledCustomerIds:${this.ListCatalogEnabledCustomerIds} - listPrivilegeByClaims:${JSON.stringify(this.listPrivilegeByClaims)}`;

  }

}
