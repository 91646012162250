import { Property } from "./property.model";

export class ProductItem {
  productId: number = 0;
  productItemId: number = 0;
  itemPrice: number = 0;
  listOfProperties: Property [] = [];
  imageURL: string = '';
  productTitleFr: string = '';
  productTitleEn: string = '';
  customerProductNumber:string = '';
  productItemNumber:string = '';
  //Permet de retourner le nomber d'unité en main d'un produit
  inforCSD_StockInfo_NetQuantityAvailable : number = 0;

  constructor() { }
}
