<div class="ss-header-container">
  <!-- LEFT CONTAINER -->

  <div class="ss-header-left">
    <!-- MENU BUTTON -->

    <button
      *ngIf="this.currentUser().isCurrentlyLogged"
      kendoButton
      [svgIcon]="allIcons.menuIcon"
      fillMode="flat"
      themeColor="secondary"
      (click)="toggleSidenav.emit()"
      class="ss-header-hover-pointer"
    ></button>

    <!-- LOGO -->

    <img src="assets/img/logo-safestock.png" />

    <!-- BUDGET -->

    <p
      *ngIf="this.currentUser().isCurrentlyLogged && canSeeRemainingBudget && showBudget"
      class="ss-header-small-blue-text"
    >
      {{ "layout.header.remainingBudget" | translate }} :
      <span class="ss-header-bold-red-text">{{ getDollorSignByLocale(remainingBudget) }}</span>
    </p>
  </div>

  <!-- RIGHT CONTAINER -->

  <div class="ss-header-right">

    <!-- Modify Personnal Comment -->
    
    <button
      class="ss-header-hover-pointer"
      kendoButton
      [svgIcon]="allIcons.trackChangesIcon"
      fillMode="flat"
      themeColor="primary"
      *ngIf="this.ShowEmployeeVisibleNotes"
      (click)="SeeUserPersonnalNotes()"
    ></button>

    <!-- HELP EMAIL -->

    <a href="mailto:vmi@spi.com"><kendo-svg-icon [icon]="allIcons.envelopeIcon" themeColor="primary"></kendo-svg-icon></a>

    <!-- MENU LANGUE-->

    <kendo-menu [openOnClick]="{ toggle: 'click' }">
      <kendo-menu-item>
        <ng-template kendoMenuItemTemplate>
          <kendo-svg-icon [icon]="allIcons.globeIcon" themeColor="primary"></kendo-svg-icon>
          {{ langue | uppercase }}
        </ng-template>
        <ng-template kendoMenuItemContentTemplate let-index="index">
          <div class="ss-header-menu-container ss-header-hover-pointer">
            <button mat-button (click)="selectLangue('fr')">Français</button>
            <button mat-button (click)="selectLangue('en')">English</button>
          </div>
        </ng-template>
      </kendo-menu-item>
    </kendo-menu>

    <!-- CART BUTTON -->

    <button
      class="ss-header-hover-pointer"
      *ngIf="canSeeCartbutton"
      kendoButton
      [svgIcon]="allIcons.cartIcon"
      fillMode="flat"
      themeColor="secondary"
      (click)="goToCart()"
      [matBadge]="numberOfItemsInCart"
    ></button>

    <!-- MENU USAGER (PROFIL / LOGOUT )-->

    <kendo-menu
      [openOnClick]="{ toggle: 'click' }"
      *ngIf="this.currentUser().isCurrentlyLogged"
    >
      <kendo-menu-item>

        <!-- CURRENT USER INFO -->

        <ng-template kendoMenuItemTemplate>
          <kendo-svg-icon [icon]="allIcons.userIcon" themeColor="primary"></kendo-svg-icon>
          {{ currentUser().userName }}
          <mat-icon *ngIf="this.authService.isCurrentlyImpersonating()">supervisor_account</mat-icon>
          </ng-template
        >
        <ng-template kendoMenuItemContentTemplate let-index="index">
          <div class="ss-header-menu-container">
            <!-- PROFILE -->

            <button mat-button (click)="OnCheckProfileClick()" routerLink="/auth/profile">
              <mat-icon>person</mat-icon>
              {{ "layout.header.button_profile" | translate }}
            </button>


            <!-- stop impersonation -->
            <button mat-button *ngIf="this.authService.isCurrentlyImpersonating()" (click)="OnClickStopImpersonate()">
              <mat-icon>supervisor_account</mat-icon>
              {{ "layout.header.button_stop_impersonation" | translate }}
            </button>


            <!-- LOGOUT -->

            <button mat-button (click)="OnLogoutClick()">
              <mat-icon>logout</mat-icon>
              {{ "layout.header.button_logout" | translate }}
            </button>
          </div>
        </ng-template>
      </kendo-menu-item>
    </kendo-menu>
  </div>
</div>
