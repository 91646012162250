import { ChannelNode } from "../product/channel-node.model";

export class ProductProduct {
  public productId:number;
  public productEntityID: number;
  public productItems: any[];
  public productNumber: string;
  public productTitleFr: string;
  public productTitleEn: string;
  public productMainImageUrl: string;
  public productBrand: string;
  public pimFieldSetID: string;
  public pimParentChannelNodeId: number;
  public productItemListPrice: number;
  public inforCSD_StockInfo_NetQuantityAvailable: number = 0;
  public productDescriptionFr: string;
  public productDescriptionEn: string;
  public defaultProductItemId: number = 0;
  public pimParentChannelNode: ChannelNode  | null = null;

  public productCustomField01Name?:  string | null = null;
  public productCustomField02Name?:  string | null = null;
  public productCustomField03Name?:  string | null = null;
  public productCustomField01Value?: string | null = null;
  public productCustomField02Value?: string | null = null;
  public productCustomField03Value?: string | null = null;

  constructor(
    productId:number = -1,
    productEntityID: number = -1,
    productItems: any[] = [],
    productNumber: string = '',
    productTitleFr: string = '',
    productTitleEn: string= '',
    productMainImageUrl: string= '',
    productBrand: string= '',
    pimFieldSetID: string= '',
    pimParentChannelNodeId: number = 0,
    productItemListPrice: number,
    productDescriptionFr: string,
    productDescriptionEn: string
  ) {
    this.productId = productId,
    this.productEntityID = productEntityID,
    this.productItems = productItems,
    this.productNumber = productNumber,
    this.productTitleFr = productTitleFr,
    this.productTitleEn = productTitleEn,
    this.productMainImageUrl = productMainImageUrl,
    this.productBrand = productBrand,
    this.pimFieldSetID = pimFieldSetID,
    this.pimParentChannelNodeId = pimParentChannelNodeId,
    this.productItemListPrice = productItemListPrice,
    this.productDescriptionFr = productDescriptionFr,
    this.productDescriptionEn = productDescriptionEn
  }
}
