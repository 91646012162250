/**
 * Liste des clé de permission, trouvé dans les claims
 */
export enum permissionCodeEnum {
  //droit au niveau des commandes (ORDER)
  PRIVILEGE_ORDER_ADMIN = 'PRIVILEGE_ORDER_ADMIN', //peut tout faire sur les orders, et voir les info ERP.
  PRIVILEGE_ORDER_CANAPPROVEORDER = 'PRIVILEGE_ORDER_CANAPPROVEORDER', //peut approuver une commande
  PRIVILEGE_ORDER_CANCREATEORDER = 'PRIVILEGE_ORDER_CANCREATEORDER', //peut créer une commande
  PRIVILEGE_ORDER_CANVIEWORDERHISTORY = 'PRIVILEGE_ORDER_CANVIEWORDERHISTORY', //peut voir l'historique des commandes
  PRIVILEGE_ORDER_CANBROWSECATALOG = 'PRIVILEGE_ORDER_CANBROWSECATALOG', //peut voir le catalog
  PRIVILEGE_ORDER_CANVIEWREPORTS = 'PRIVILEGE_ORDER_CANVIEWREPORTS', //peut voir le catalog

  PRIVILEGE_CUSTOMER_MODIFY = 'PRIVILEGE_CUSTOMER_MODIFY', //peut voir le catalog
  PRIVILEGE_CUSTOMER_ADMIN = 'PRIVILEGE_CUSTOMER_ADMIN', //peut voir le catalog

  PRIVILEGE_USERGROUP_ADMIN = 'PRIVILEGE_USERGROUP_ADMIN', //peut voir le catalog
  PRIVILEGE_USERGROUP_MODIFY = 'PRIVILEGE_USERGROUP_MODIFY', //peut voir le catalog

  PRIVILEGE_PRODUCT_MODIFY = 'PRIVILEGE_PRODUCT_MODIFY', //peut voir le catalog
  PRIVILEGE_PRODUCT_ADMIN = 'PRIVILEGE_PRODUCT_ADMIN', //peut voir le catalog

  PRIVILEGE_USER_CANBROWSEASANOTHERUSER = 'PRIVILEGE_USER_CANBROWSEASANOTHERUSER',//peut s'authentifier en tant qu'un autre usager (impersonate)
  PRIVILEGE_USER_ADMIN = 'PRIVILEGE_USER_ADMIN',  //peut tout faire en lien avec le/les User attaché  [dans TOUS, ou dans le CLIENT ou UserGroup attaché] - pas de shipto possible ici!
  PRIVILEGE_USER_MODIFY = 'PRIVILEGE_USER_MODIFY',  //peut modifier quelques champs de  l'usager, sauf quelques champs "spéciaux"  (excemption, exception, budget..), peut aussi créer des usagers Client Employe

  PRIVILEGE_SYSTEM_ADMIN = 'PRIVILEGE_SYSTEM_ADMIN', //peut gérer les paramètres systemes (ex: gérer les roles de sécurité,voir les LOGS, etc..) - 

}

/**
 * Cet enum contient des morceaux de l'encodage des claims de permission.
 * exemple d'un claims :  "PRIVILEGE_ORDER_CANVIEWORDERHISTORY": ["|SOURCE:AssignedByRole|ROLE:SUPERVISEUR|CUSTID:105302|UGID:210|AUID:|"]
 *    --> on peut voir que le claim contient des morceaux de permission, séparé par des pipes (|).  la source de ce claim est via un role, le nom du role est 'superviseur', pour le customer 105302,  sur le userGroup 210,  et aucun userID spécifié
 */
export enum permissionPartCodeEnum {
  SOURCE= 'SOURCE', //Source de l'assignation [AssignedByRole, DirectAssign]
  ROLE = 'ROLE', //le role de l'assignation
  CUSTID= 'CUSTID', //le customerID
  UGID = 'UGID', //userGroupID
  AUID = 'AUID',  //assigned userID
}
