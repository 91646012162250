import { Component, OnInit } from '@angular/core';
import { State } from '@progress/kendo-data-query';
import { DialogRef, DialogContentBase } from '@progress/kendo-angular-dialog';
import { GridDataResult } from '@progress/kendo-angular-grid';
import * as svgIcons from '@progress/kendo-svg-icons';

import { CustomerService } from '../customer.service';
import { GridService } from 'src/app/shared/grid.service';


@Component({
  selector: 'app-customer-popup',
  templateUrl: './customer-popup.component.html',
  styleUrls: ['./customer-popup.component.scss'],
})
export class CustomerPopupComponent extends DialogContentBase implements OnInit {
  public allIcons = svgIcons;
  
  title = '';
  button_cancel = '';

  isLoading = false;
  gridView!: GridDataResult;
  id!: number;
  
  pageable = {
    pageSizes: this.gridService.pageSizes,
  };

  public state: State = {
    take: this.gridService.take,
    skip: 0,
  };

  constructor(
    public override dialog: DialogRef,
    public gridService: GridService,
    private customerService: CustomerService
  ) {
    super(dialog);
    // fetch customer
    this.isLoading = true;
    this.customerService.listCache().subscribe( response => {
      this.isLoading = false;
      this.gridView = {
        data: response,
        total: response.length,
      };
    })
  }

  ngOnInit(): void {}

  cancel() {
    this.dialog.close(false);
  }

  select(e: any) {
    this.dialog.close(e.dataItem);
  }

  dataStateChange(state: State) {
    this.isLoading = true;
    this.gridService.setRowPerPages(state);
    this.state = state;
    this.customerService.odata(this.state).subscribe((response) => {
      this.isLoading = false;
      this.gridView = {
        data: response.value,
        total: response['@odata.count'],
      };
    });
  }
}
