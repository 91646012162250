import { Component, OnInit } from '@angular/core';
import { DialogRef, DialogContentBase, } from '@progress/kendo-angular-dialog';
import { TranslationManagementService } from 'src/app/core/translation/translate.service';
import * as svgIcons from '@progress/kendo-svg-icons';

@Component({
  selector: 'app-pop-confirm',
  templateUrl: './pop-confirm.component.html',
  styleUrls: ['./pop-confirm.component.scss'],
})
export class PopConfirmComponent extends DialogContentBase implements OnInit {
  public allIcons = svgIcons;

  title = '';
  message = '';
  button_yes = '';
  button_no = '';

  constructor(
    public override dialog: DialogRef,
    private translateService: TranslationManagementService
  ) {
    super(dialog);
  }

  ngOnInit() {
    let message = this.translateService.translateKey(this.message);
    let messageContainer = document.getElementById('message');
    if (messageContainer) {
      messageContainer.innerHTML = message;
    }
  }

  cancel() {
    this.dialog.close(false);
  }

  confirm() {
    this.dialog.close(true);
  }
}
