import { Component, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { PermissionService } from 'src/app/core/permission.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { GlobalDataService } from 'src/app/core/global-data/global-data.service';
import { UtilService } from 'src/app/shared/util.service';
import { combineLatest, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { CartLine } from 'src/app/core/global-data/global-data-models/cart-line.model';
import * as svgIcons from '@progress/kendo-svg-icons';
import { AuthUser } from 'src/app/core/auth/auth-user.model';
import { User } from 'src/app/module/user/user.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public allIcons = svgIcons;

  private unsubNotifier = new Subject<void>();

  isLoading = false;
  langue = this.translate.currentLang
  remainingBudget:number = 0;
  canSeeRemainingBudget: boolean = false; // permission
  showBudget = false; // limitation budget
  logState:boolean = false
  numberOfItemsInCart:number | undefined  = undefined;
  EmployeeVisibleNotes: string = '';
  ShowEmployeeVisibleNotes: boolean = false;
  canSeeCartbutton: boolean = false;

  @Output() toggleSidenav = new EventEmitter();



  ngOnInit(): void {
    //LOG_BASE_LifeCycle - this.utilService.logInformation('HeaderComponent->ngOnInit');

    //on va ÉCOUTER le changement de langue, et déclencher un refresh au besoin
    this.translate.onLangChange
      .pipe(
        tap(event => {
          this.langue = event.lang;
        })
      )
      .subscribe();


    //on va ÉCOUTER le changement d'uysager en cours, et déclencher un refresh au besoin.  Le déclenchement se fait dans authService, via une méthode qui fait un next sur le currentAuthUserBehavior
    this.authService.currentAuthUserBehavior
    .pipe(
      tap((x) => {
        //une fois que l'usager est changé, on rafraichit le header
        this.refreshHeaderUserchange(x);
        
        //on va chercher la note de l'usager
        this.EmployeeVisibleNotes = x.employeePersonnalComment ?? '';

        //on va convertir les \n en html : 
        this.EmployeeVisibleNotes = this.EmployeeVisibleNotes.replace(/\n/g, '<br/>');
      
        // Si on a une note on débarre le bouton
        if(this.EmployeeVisibleNotes.length > 0)
          this.ShowEmployeeVisibleNotes = true;
        else
          this.ShowEmployeeVisibleNotes = false;
      })
      ,takeUntil(this.unsubNotifier)
    )
    .subscribe();
  }

  /** Cette méthode permet de rafraichir le header lorsque l'usager change.  Permet de centraliser le code de rafraichissement du header,
   *
   * @param newUser
   */
  refreshHeaderUserchange(newUser:AuthUser) {
    //LOG_BASE_LifeCycle - this.utilService.logInformation(`HeaderComponent->refreshHeaderUserchange()   ${this.currentUser().GetDebugUserInfo()}`);
    this.canSeeCartbutton = this.permissionService.canSeeCartButton();

    // Le calcul du budget restant est affecté par le user order overview et les cart items alors
    // le tour de passe passe est de subscribe à c'est deux là en plus du cart pour avoir un header à jour
    let uoo = this.globalDataService.userOrderOverview.asObservable();
    let cart = this.globalDataService.cart.asObservable();
    let cartItems = this.globalDataService.cartItems.asObservable();

    //on exécute le tout en une seule fois
    let myAsyncData = combineLatest([uoo,cart,cartItems])

    myAsyncData
      .pipe(
        //
        tap(([uoo,cart,cartItems]) => {
          this.numberOfItemsInCart = this.getNumberOfCartItems(cart)
        }),

        //*****************************
        //Gestion des budget
        switchMap(([uoo,cart,cartItems]) => {
          return this.globalDataService.canUserSeeHeaderBudget();
        }),
        tap(res => {
          this.canSeeRemainingBudget = res;
        }),
        //*****************************

        //*****************************
        //gestion du remaining
        switchMap(res => {
          return this.globalDataService.getRemainingBudget();
        }),
        tap(budgetLeft => {
          this.remainingBudget = budgetLeft;
        }),
        //*****************************


        takeUntil(this.unsubNotifier)
      )
      .subscribe();

    //  show budget
    this.globalDataService.budgetAmountLimitationActivated
      .pipe(
          tap(response => {
            this.showBudget = response;
          }),
          takeUntil(this.unsubNotifier))
      .subscribe();


  }

  ngOnDestroy(): void {
    //LOG_BASE_LifeCycle - this.utilService.logInformation(`HeaderComponent->ngOnDestroy  - UserData:${this.currentUser().GetDebugUserInfo()}`);

    this.unsubNotifier.next();
    this.unsubNotifier.unsubscribe();
  }

  constructor(
    public authService: AuthService,
    public permissionService: PermissionService,
    private translate: TranslateService,
    private router: Router,
    private globalDataService: GlobalDataService,
    private utilService: UtilService,
  ) { }

  selectLangue(langue: string) {
    this.langue = langue;
    this.translate.use(langue)
  }

  OnLogoutClick() {
    this.logState = false;
    this.globalDataService.clearGlobalData();
    //LOG_BASE_LifeCycle - this.utilService.logInformation(`************************** HeaderComponent->logout-> **************************`);

    this.authService.logout(true)
      .pipe(
        tap(() => {
          //LOG_BASE_LifeCycle - this.utilService.logInformation(`HeaderComponent->logout->TAP() -> UserData: ${this.currentUser().GetDebugUserInfo()}`);
          this.router.navigate(['/auth']);

        }),
        takeUntil(this.unsubNotifier))
      .subscribe();
  }

  /**
   * Cette méthode permet de retourner à l'usager original.  le JWT sera changé pour celui de l'usager original.
   */
  OnClickStopImpersonate() {
    if (this.authService.isCurrentlyImpersonating()) {
      this.isLoading = true;
      //on détruit toute les données en localStorage de l'usager usurpé
      this.globalDataService.clearGlobalData();

      //on détruit le JWT de l'usager usurpé
      this.globalDataService.StopImpersonationAndRefreshData()

        .subscribe(() => {
          //LOG_BASE_LifeCycle - this.utilService.logInformation(`HeaderComponent->OnClickStopImpersonate->TAP() -> UserData: ${this.currentUser().GetDebugUserInfo()}`);
          this.router.navigate(['/auth/home']);

        });

    }
  }

  goToCart() {
    this.router.navigate(['/cart']);
  }

  SeeUserPersonnalNotes() {
    let title = 'module.user.model.personalComment';
    let msg = this.EmployeeVisibleNotes;
    this.utilService.alert(title, msg);
  }

  private getNumberOfCartItems(cart:CartLine[]) : number | undefined {
    let numberOfItemsInCart = 0

    for (const cartLine of cart) {
      numberOfItemsInCart += cartLine.quantity
    }
    if (numberOfItemsInCart === 0)
      return undefined;
    else
      return numberOfItemsInCart
  }

  public getDollorSignByLocale(budget:number):string {
    return this.utilService.putDollarSignByLocal(budget.toFixed(2));
  }

  /** Cette méthode de TEST permet de déclencher un REFRESH des tokens, avec le RefershToken.
   *
   */
  public OnCheckProfileClick()
  {
      this.authService.refreshAuthTokens()
        .pipe(
          tap((retour)=>{console.log(retour)}),
          takeUntil(this.unsubNotifier))
        .subscribe();

  }


  /** Cette méthode permet d'aller chercher la version 'live' de l'usager connecté (pas via un subscription).  Pratique pour tester localement 'IsLogged... avoir le nom/prénom, etc,  sans avoir à lier le TemplateHTML à authhService
   * @returns
   */
  public currentUser(): AuthUser {return this.authService.GetCurrentUser()};
}

