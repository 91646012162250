export class UserGroup {
  public userGroupHeaderId: number = 0;

  public addressSelectionMode: string = '';
  public assignedSalesRepEmail: string = '';
  public customerId: number = 0;
  public dateEnd: string = '';
  public dateStart: string = '';
  public defaultPurchaseOrderNumber: string = '';
  public defaultShipToId: string = '';
  public isActiveInSafestock: boolean = true;
  public isSupervisorApprovalRequired: boolean = false;
  public maximumAllowedAmount: number | null = 0;
  public minimumAmountToSkipTransportFee: number = 0;
  public mustHidePrices: boolean = false;
  public transportFeeAmount: number = 0;
  public userGroupDescription: string = '';
  public userGroupDetails?: any = null;
  public userOverride_UserId: string = '';
  public productGroupingName: string = '';
  public purchaseOrderNumberSelectionMode: string = 'NotSelected';
  public orderEntrepotProductItemNumber: string = '';
  public orderEntrepotProductItemNote: string = '';
  public orderTransformationProductItemNumber: string = '';
  public orderTransformationProductItemNote: string = ''; 

  constructor() {
  }
}



export enum enum_POSelectionMode {
  NotSelected = "NotSelected",
  UseDefaultUserGroupValue_EmployeeCannotChange = "UseDefaultUserGroupValue_EmployeeCannotChange",
  POEnteredBySupervisor = "POEnteredBySupervisor",
  POEnteredByEmployee = "POEnteredByEmployee"
}
