import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { State, toODataString } from '@progress/kendo-data-query';
import { DialogService, DialogRef, DialogCloseResult } from '@progress/kendo-angular-dialog';

import { PopConfirmComponent } from 'src/app/shared/component/pop-confirm/pop-confirm.component';
import { PopPasswordComponent } from 'src/app/module/auth/pop-password/pop-password.component';
import { User } from './user.model';
import { UserPopupComponent } from './user-popup/user-popup.component';
import { UtilService } from 'src/app/shared/util.service';
import { UserImportComponent } from './user-import/user-import.component';
import { UserOrderOverview } from 'src/app/core/global-data/global-data-models/user-order-overview.model';

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private users: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);
  public user: ReplaySubject<User> = new ReplaySubject<User>(1);
  public usersOdata: ReplaySubject<any> = new ReplaySubject<any>(1);
  private dialog!: DialogRef;

  constructor(
    private dialogService: DialogService,
    private http: HttpClient,
    private router: Router,
    private utilService: UtilService
  ) {}

  initOne() {
    this.user.next({
      id: '',
      userName: '',
      userFirstName: '',
      userFullName: '',
      userLastName: '',
      isClientEmploye: true,
      //mainUserType: '',
      email: '',
      generalInternalComment: '',
      preferedLanguage: '',
      isEnabled: false,
      newInputAssigneduserGroupHeaderId: 0,
      newInputPassword: '',
      assignedSalesRepEmail: '',
      overrideEmployeMaximumAllowedAmount: null,
      overrideAccessDateStart: '',
      overrideAccessDateEnd: '',
      overrideEmployeMinimumAmountToSkipTransportFee: null,
      overrideEmployeTransportFeeAmount: null,
      commentToShowOnOrder: '',
      employeePersonnalComment: '',
    });
  }

  list(): Observable<User[]> {
    return this.http.get<User[]>(apiUrl + 'security/UserAccount/Get', {}).pipe(
      tap((response: any) => {
        this.users.next(response);
      })
    );
  }

  /**
   * GetUserOrderOverview - Permet de retourner un sommaire des consommations d'un usager
   */
  GetUserOrderOverview(id: string): Observable<any> {
    return this.GetUserOrderOverviewOfAnotherUser(id)
      .pipe(
        tap((response: any) => {
          this.user.next(response);
        })
      );

    /*
    retour :
    {
          "maxAvailableAmount": 1000.00,
          "amountRemaining": 842.3100,
          "actualPurchasedAmount": 157.6900,
          "minDateToCheck": "2021-12-07T00:00:00",
          "nbOrderTotal": 9,
          "nbOrderNotApproved": 0,
          "nbOrderCompleted": 0,
          "nbOrderInCartMode": 9
    }
    */
  }

  GetUserOrderOverviewOfAnotherUser(id: string): Observable<UserOrderOverview> {
    let uri = `${apiUrl}security/UserAccount/${id}/GetUserOrderOverview`;
    return this.http.get<UserOrderOverview>(uri);
  }

  read(id: string, returnValue?: boolean): Observable<User> {
    return this.http.get<User>(apiUrl + 'security/UserAccount/' + id, {}).pipe(
      tap((response: any) => {
        if (returnValue)
          return response;
        else
          this.user.next(response);
      })
    );
  }

  create(user: any): Observable<any> {
    // todo: potentiel manipulation a faire avec les champs date, checkbox, etc
    return this.http.post<any>(apiUrl + 'security/UserAccount/', user).pipe(
      tap((response: any) => {
        this.user.next(response);
      })
    );
  }

  update(user: any): Observable<any> {
    // todo: potentiel manipulation a faire avec les champs date, checkbox, etc
    return this.http
      .put<any>(apiUrl + 'security/UserAccount/' + user.id, user)
      .pipe(
        tap((response: any) => {
          this.user.next(response);
          //this.user.next(response); // désactivé en attendand que la réponse du GET, POST et PUT soit pareil
        })
      );
  }

  // ODATA

  odata(state: State): Observable<any> {
    let _state  = this.utilService.makeStateCaseInsensitive(state)
    let uri = `${apiUrl}security/UserAccount?${toODataString(_state)}&$count=true`
    return this.http.get<User>(uri).pipe(
      tap((response: any) => {
        this.usersOdata.next(response);
      })
    );
  }

  // ODATA  : WITH USER GROUP

  odataWithUserGroupFilter(state: State, userGroups?:number[], customers?:number[] ): Observable<any> {

    let _state = this.utilService.makeStateCaseInsensitive(state);

    // odatastring de base

    let odataCustomString = "&$expand=UserRightAssignations($filter=RoleId eq '96041a3c-8bec-4cac-aba5-fd3d1eec9c69';$select=assignedUserGroupId,assignedCustomerId,roleId)";

    let odataFilter = '';

    // user groups filter

    if (userGroups && userGroups.length){
      let odataExpandFilter = '';
      userGroups.forEach((userGroupHeaderId:number) => {
        if (odataExpandFilter != '') odataExpandFilter += ' or ';
        odataExpandFilter += 'c/assignedUserGroupId eq ' + userGroupHeaderId;
      });
      odataFilter += "UserRightAssignations/any(c: c/roleId eq '96041a3c-8bec-4cac-aba5-fd3d1eec9c69' and (" + odataExpandFilter + "))";
    }

    // customer filter

    if (customers && customers.length){
      let odataExpandFilter = '';
      customers.forEach((customerId:number) => {
        if (odataExpandFilter != '') odataExpandFilter += ' or ';
        odataExpandFilter += 'c/assignedCustomerId eq ' + customerId;
      });
      if (odataFilter != '') odataFilter += ' and ';
      odataFilter += "UserRightAssignations/any(c: c/roleId eq '96041a3c-8bec-4cac-aba5-fd3d1eec9c69' and (" + odataExpandFilter + "))";
    }

    // convert state to string et s'assurer que filter est le dernier param
    let odataStateString = toODataString(_state);
    let odataStateArray = odataStateString.split('&');
    if (odataStateArray && odataStateArray.length > 0) {
      let indexFilter = odataStateArray.findIndex((p: any) => p.indexOf('$filter=') != -1);
      if (indexFilter < odataStateArray.length - 1) {
        odataStateArray.push(odataStateArray.splice(indexFilter, 1)[0]);
        odataStateString = odataStateArray.join('&');
      }
    }

    // merge custom et filter string avec state
    let odataFullString = '';
    if (odataFilter != '') {
      odataFullString = odataCustomString + "&" + odataStateString + (odataStateString.indexOf('$filter') != -1 ? " and " : "&$filter=") +  odataFilter;
    }
    else
      odataFullString = odataCustomString + "&" + odataStateString;

    let uri = `${apiUrl}security/UserAccount?${odataFullString}&$count=true`;

    // fetch
    return this.http.get<User>(uri).pipe(
      tap((response: any) => {
        return response;
      })
    );
  }

  // ODATA  : STRING

  odataWithExpand(state: State): Observable<any> {
    let _state  = this.utilService.makeStateCaseInsensitive(state)
    let odataString = "&$expand=UserRightAssignations($filter=RoleId eq '96041a3c-8bec-4cac-aba5-fd3d1eec9c69';$select=assignedUserGroupId,assignedCustomerId,roleId)";

    let uri = `${apiUrl}security/UserAccount?${odataString}&${toODataString(_state)}&$count=true`
    return this.http.get<User>(uri).pipe(
      tap((response: any) => {
        this.usersOdata.next(response);
      })
    );
  }

  odataStr(state: State, oDataString: string): Observable<any> {
    let oDataFullString = this.utilService.getODataFullString(state, oDataString);
    let uri = `${apiUrl}security/UserAccount?${oDataFullString}&$count=true`
    return this.http.get<any>(uri);
  }

  // POPUP USER LIST

  public popup(title: string, oDataStr: string): any {
    if (title == '') title = 'module.user.popup.title';
    const confirmObservable = new Observable((observer) => {
      // open dialog
      this.dialog = this.dialogService.open({
        content: UserPopupComponent,
        minWidth: 400,
        height: 800,
        maxWidth: 900,
      });
      // set default values
      const dialogData = this.dialog.content.instance as UserPopupComponent;
      dialogData.oDataStr = oDataStr;
      dialogData.title = title;
      dialogData.button_cancel = 'module.user.popup.button_cancel';
      // subscribe to response
      this.dialog.result.subscribe((result) => {
        if (result instanceof DialogCloseResult) {
          // cancel
          observer.next(false);
        } else {
          // confirm
          observer.next(result);
        }
      });
    });
    return confirmObservable;
  }

  // DIALOG : RESET MY PASSWORD

  popChangeMyPassword(id: string) {
    let dialog: DialogRef;
    // open dialog
    dialog = this.dialogService.open({
      content: PopPasswordComponent,
      minWidth: 450,
    });

    // set default values
    const resetFormData = dialog.content.instance as PopPasswordComponent;
    resetFormData.modifySelf = true;
    // subscribe to response
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        // cancel
      } else {
        // submit
        this.http
          .put<any>(
            apiUrl +
              'security/UserAccount/ForceChangePassword?userID=' +
              id +
              '&NewPassword=' +
              encodeURIComponent(resetFormData.newPassword) +
              '&OldPassword=' +
              encodeURIComponent(resetFormData.oldPassword),
            {}
          )
          .subscribe((response) => {
            // notify  on success
            if (
              response &&
              response.title == 'AUTH_CHANGEPASSWORD_OPERATION_SUCCEEDED'
            )
              this.utilService.notify(
                'module.auth.reset-password.success_reset_password',
                'success'
              );
          });
      }
    });
  }

  // DIALOG : RESET OTHER PASSWORD

  popChangePassword(id: string) {
    let dialog: DialogRef;
    // open dialog
    dialog = this.dialogService.open({
      content: PopPasswordComponent,
      minWidth: 450,
    });

    // set default values
    const resetFormData = dialog.content.instance as PopPasswordComponent;

    // subscribe to response
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        // cancel
      } else {
        // submit
        this.http
          .put<any>(
            apiUrl +
              'security/UserAccount/ForceChangePassword?userID=' +
              id +
              '&NewPassword=' +
              encodeURIComponent(resetFormData.newPassword),
            // + '&OldPassword=' +
            //resetFormData.oldPassword,
            {}
          )
          .subscribe((response) => {
            // notify  on success
            if (
              response &&
              response.title == 'AUTH_CHANGEPASSWORD_OPERATION_SUCCEEDED'
            )
              this.utilService.notify(
                'module.auth.reset-password.success_reset_password',
                'success'
              );
          });
      }
    });
  }

  forceChangePassword(id: string) {
    let dialog: DialogRef;
    // open dialog
    dialog = this.dialogService.open({
      content: PopConfirmComponent,
      minWidth: 400,
    });
    // set default values
    const dialogData = dialog.content.instance as PopConfirmComponent;
    dialogData.title = 'module.user.edit.forceChangePassword.title';
    dialogData.message = 'module.user.edit.forceChangePassword.message';
    // subscribe to response
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        // no
      } else {
        // yes
        this.update({
          id: id,
          mustChangePasswordNextLogin: true,
        }).subscribe((response) => {
          if (response && response.id) {
            this.utilService.notify(
              'module.user.edit.forceChangePassword.success',
              'success'
            );
          }
        });
      }
    });
  }

  forceChangePasswordWithToken(
    userId: string,
    newPassword: string,
    token: string
  ): Observable<any> {
    let encodedToken = encodeURIComponent(token);

    let MyUri =
      apiUrl +
      'security/UserAccount/ForceChangePasswordWithToken?userId=' +
      userId +
      '&newPassword=' +
      encodeURIComponent(newPassword) +
      '&token=' +
      encodedToken;

    let MyBody = {
      userId: userId,
      newPassword: newPassword,
      token: encodedToken,
    };
    return this.http.post<any>(MyUri, MyBody);
  }

  forgotPassword(userName: string): Observable<any> {
    let callBackURI =
      location.protocol + '//' + location.host + '/auth/reset-password';
    return this.http.post<any>(
      apiUrl +
        'security/UserAccount/SendEmailForResetPasswordToken?UserName=' +
        userName +
        '&CallBackURI=' +
        callBackURI,
      {}
    );
  }

  importUserList() {
    let dialog: DialogRef;
    // open dialog
    dialog = this.dialogService.open({
      content: UserImportComponent,
    });
    // set default values
    const dialogData = dialog.content.instance as UserImportComponent;
    // subscribe to response
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        // cancel
      } else {
        // confirm : send file
        const formData = new FormData();
        formData.append('file', dialogData.file);
        this.http
          .post<any>(
            apiUrl +
              'security/UserAccount/AddUserAccountsFromFile/' +
              dialogData.userGroupHeaderId,
            formData
          )
          .subscribe((response) => {
            // notify
            this.utilService.notify(
              'module.user.import.success_import',
              'success'
            );
          });
      }
    });
  }

  downloadUserImportTemplateFile(): Observable<any> {
    return this.http.post<any>(
      apiUrl + 'security/UserAccount/downloadUserImportTemplateFile',
      { contentType: 'blob' as 'application/vnd.ms-excel' }
    );
  }

  /*
  * Cette fonction sert à retourner le userGroupHeaderId d'un usager selon la nouvelle norme d'assignation 
  * de user group à travers le systèm de permissions
  */

  getUserGroupHeaderId(user: any): number {
    let userGroupHeaderId = 0;
    if (user && user.userRightAssignations && user.userRightAssignations.length >= 0) {
      let rightEmployee = user.userRightAssignations.find((ur: any) => ur.roleId == '96041a3c-8bec-4cac-aba5-fd3d1eec9c69');
      if (rightEmployee) {
        userGroupHeaderId = rightEmployee.assignedUserGroupId;
      }
    }
    return userGroupHeaderId;
  }

  /*
  * Cette fonction sert à retourner le customerId d'un usager selon la nouvelle norme d'assignation 
  * de user group à travers le systèm de permissions
  */

  getCustomerId(user: any): number {
    let customerId = 0;
    if (user && user.userRightAssignations && user.userRightAssignations.length >= 0) {
      let rightEmployee = user.userRightAssignations.find((ur: any) => ur.roleId == '96041a3c-8bec-4cac-aba5-fd3d1eec9c69');
      if (rightEmployee) {
        customerId = rightEmployee.assignedCustomerId;
      }
    }
    return customerId;
  }
}
