import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { PublicClientApplication, BrowserCacheLocation, LogLevel, InteractionType } from '@azure/msal-browser';
import { MsalGuard, MsalRedirectComponent, MsalModule } from '@azure/msal-angular';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';

import { ErrorInterceptor } from './core/error/error.interceptor';
import { ErrorHandlerService } from './core/error/error-handler.service';

import { AppComponent } from './app.component';

import { LayoutComponent } from './layout/layout/layout.component';
import { HeaderComponent } from './layout/header/header.component';
import { SidenavComponent } from './layout/sidenav/sidenav.component';
import { LoadingComponent } from './layout/loading/loading.component';

import { LocalStorage } from './core/local-storage.enum';
import { IconsModule } from '@progress/kendo-angular-icons';

import { MessageService } from '@progress/kendo-angular-l10n';
import { TranslationManagementService } from './core/translation/translate.service';
import { environment } from 'src/environments/environment';

// translation loader
export function HttpLoaderFactory(http: HttpClient) {  return new TranslateHttpLoader(http); }

// SSO : MSAL (Microsoft Authentication Library)

const GRAPH_ENDPOINT = 'Enter_the_Graph_Endpoint_Herev1.0/me';
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
const protectedResourceMap = new Map<string, Array<string>>();
protectedResourceMap.set(GRAPH_ENDPOINT, ['user.read']);

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log('loggerCallback', message);
}

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    HeaderComponent,
    SidenavComponent,
    LoadingComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    SharedModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: environment.sso_microsoft.MSEntraApplicationID, //ApplicationID dans Entra
          redirectUri: environment.sso_microsoft.MSOidcRedirectUri, //chemin de retour que MS va prendre
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: isIE,
        },
        system: {
          loggerOptions: {
            //loggerCallback: loggerCallback,
            logLevel: LogLevel.Info,
            piiLoggingEnabled: false,
          },
        },
      }),
      {
        interactionType: InteractionType.Redirect, // MSAL Guard Configuration
        authRequest: {
          scopes: ['user.read'],
        },
      },
      {
        interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
        protectedResourceMap,
      }
    ),
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          return localStorage.getItem(LocalStorage.JWT);
        },
        allowedDomains: [
          'safestock-api-dev.azurewebsites.net',
          'safestock-api-staging.azurewebsites.net',
          'safestock-api-prod.azurewebsites.net',

          'api.safestock.ca',
          'preheat.api.safestock.ca',
          'dev.api.safestock.ca',
          'staging.api.safestock.ca',

          'localhost:7267',
          'localhost:44388',
          'localhost:4200',
        ],
      },
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'fr',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    IconsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    {
      provide: MessageService,
      useClass: TranslationManagementService
    },
    MsalGuard,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
