<div class="ss-sidenav-main"
     [class.ss-sidenav-main-mini]="!expanded">
  <!-- HEADER INFO -->

  <div class="ss-sidenav-header-container"
       routerLink="/auth/home" >
    <div *ngIf="locale === 'fr'"
         class="ss-sidenav-header-logo ss-sidenav-hover-pointer"></div>
    <div *ngIf="locale === 'en'"
         class="ss-sidenav-header-logo-en ss-sidenav-hover-pointer"></div>

  </div>

  <!-- USER INFO : CLIENT_EMPLOYE -->
  <div class="ss-sidenav-user-container"
       *ngIf="canSeeSidenavUserInfo">

    <!-- LOGO -->

    <div *ngIf="customerInfo.mainLogoURI && customerInfo.mainLogoURI.length > 0"
         class="ss-sidenav-user-logo"
         [style]="'background-image: url(' + customerInfo.mainLogoURI + ')'"></div>

    <!-- INFO USAGER -->

    <div class="ss-sidenav-user-customer">{{ customerInfo.customerName }}</div>
    <div class="ss-sidenav-user-name"> {{ this.currentUser().fullName }} </div>
    <div class="ss-sidenav-user-name"> #{{ this.currentUser().userName }}</div>
  </div>

  <!-- MENU CONTAINER -->

  <div class="ss-sidenav-menu-container">
    <!-- HOME -->
    <a class="ss-sidenav-menu-item"
       routerLink="/auth/home"
       routerLinkActive="SideNavItemSelected"
       mat-button>
      <mat-icon>home</mat-icon>
      <span>{{ "layout.sidenav.button_homePage" | translate }}</span>
    </a>
    <!-- CUSTOMER -->
    <a *ngIf="canSeeSidenavCustomer"
       class="ss-sidenav-menu-item"
       routerLink="/customer"
       routerLinkActive="SideNavItemSelected"
       mat-button>
      <mat-icon>contact_emergency</mat-icon>
      <span>{{ "layout.sidenav.button_customer" | translate }}</span>
    </a>

    <!-- USER GROUP -->
    <a *ngIf="canSeeSidenavUserGroup"
       class="ss-sidenav-menu-item"
       routerLink="/user-group"
       routerLinkActive="SideNavItemSelected"
       mat-button>
      <mat-icon>groups</mat-icon>
      <span>{{ "layout.sidenav.button_user_group" | translate }}</span>
    </a>

    <!-- REPORT -->

    <a *ngIf="canSeeSidenavReport"
       class="ss-sidenav-menu-item"
       routerLinkActive="SideNavItemSelected"
       routerLink="/report"
       mat-button>
      <mat-icon>pie_chart</mat-icon>
      <span>{{ "layout.sidenav.button_report" | translate }}</span>
    </a>

    <!-- ORDER -->

    <a *ngIf="canSeeSidenavOrder"
       class="ss-sidenav-menu-item"
       routerLink="/order"
       routerLinkActive="SideNavItemSelected"
       mat-button>
      <mat-icon>receipt</mat-icon>
      <span>{{ "layout.sidenav.button_order" | translate }}</span>
    </a>

    <!-- SECURITY -->

    <kendo-expansionpanel class="ss-sidenav-menu-item-expansionpanel"
                          [class.ss-sidenav-menu-item-expansionpanel-opened]="securityExpanded"
                          *ngIf="canSeeSidenavUser || canSeeSidenavUserRight"
                          [title]="'layout.sidenav.section_security' | translate"
                          [expanded]="securityExpanded"
                          (expandedChange)="onSecurityExpandedChange($event)">
      <ng-template kendoExpansionPanelTitleDirective>
        <div class="ss-expand-title"><mat-icon>security</mat-icon><span>{{ "layout.sidenav.section_security" | translate }}</span></div>
      </ng-template>


      <!-- USER  -->
      <!-- le 'exact' fait en sorte que /user/right n'active pas le menu ici-->
      <a *ngIf="canSeeSidenavUser"
         class="ss-sidenav-menu-item"
         routerLink="/user"
         [routerLinkActiveOptions]="{exact: true}"
         routerLinkActive="SideNavItemSelected"
         mat-button><mat-icon>person</mat-icon><span>{{ "layout.sidenav.button_user" | translate }}</span></a>

      <!-- USER RIGHTS  -->

      <a *ngIf="canSeeSidenavUserRight"
         class="ss-sidenav-menu-item"
         routerLink="/user/right"
         [queryParams]="{ searchMode: 'true'}"
         routerLinkActive="SideNavItemSelected"
         mat-button><mat-icon>badge</mat-icon><span>{{ "layout.sidenav.button_user_right" | translate }}</span></a>



    </kendo-expansionpanel>

    <!-- SYSTEM -->
    <kendo-expansionpanel class="ss-sidenav-menu-item-expansionpanel"
                          [class.ss-sidenav-menu-item-expansionpanel-opened]="systemExpanded"
                          *ngIf="canSeeSystemSideNav"
                          [title]="'layout.sidenav.section_system' | translate"
                          [expanded]="systemExpanded"
                          (expandedChange)="onSystemExpandedChange($event)">
      <ng-template kendoExpansionPanelTitleDirective>
        <div class="ss-expand-title"><mat-icon>home_repair_service</mat-icon><span>{{ "layout.sidenav.section_system" | translate }}</span></div>
      </ng-template>

      <!-- Audit Logs  -->

      <a *ngIf="canSeeSidenavAuditLogs"
         class="ss-sidenav-menu-item"
         routerLink="/system/audit-logs"
         routerLinkActive="SideNavItemSelected"
         mat-button><mat-icon>fingerprint</mat-icon><span>{{ "layout.sidenav.button_Audit_Logs" | translate }}</span></a>

    </kendo-expansionpanel>



    <!-- PRODUCT -->

    <a *ngIf="canSeeSidenavProduct"
       class="ss-sidenav-menu-item"
       routerLink="/product"
       routerLinkActive="SideNavItemSelected"
       mat-button>
      <mat-icon>medication</mat-icon>
      <span>{{ "layout.sidenav.button_product" | translate }}</span>
    </a>

    <!-- CATALOG -->

    <div *ngIf="canSeeSidenavCatalog"
         class="ss-sidenav-spacer"></div>

    <a *ngIf="canSeeSidenavCatalog"
       class="ss-sidenav-menu-item ss-sidenav-menu-item-catalog"
       routerLink="/catalog"
       routerLinkActive="SideNavItemSelected"
       mat-button>
      <mat-icon>menu_book</mat-icon>
      <span>{{ "layout.sidenav.button_catalog" | translate }}</span>
    </a>

  </div>
</div>
