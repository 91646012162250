import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, mergeMap, tap, retry, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { DialogService, DialogCloseResult } from '@progress/kendo-angular-dialog';

import { Product } from './product.model';
import { GlobalDataService } from 'src/app/core/global-data/global-data.service';
import { UtilService } from 'src/app/shared/util.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { PermissionService } from 'src/app/core/permission.service';
import { AddToCartPopupComponent } from './add-to-cart-popup/add-to-cart-popup.component';

@Injectable({
  providedIn: 'root',
})
export class CatalogService {
  private products: BehaviorSubject<Product[]> = new BehaviorSubject<Product[]>([]);

  constructor(
    private dialogService: DialogService,
    private http: HttpClient,
    private router: Router,
    private utilService: UtilService,
    private globalDataService: GlobalDataService,
    private authService: AuthService,
    private permissionService: PermissionService
  ) {}

  private BASE_URL = environment.apiUrl;
  private BASE_CATALOG_URL = `${environment.apiUrl}Product/Catalog/`;

  public getProductCatalogByCustomer(customerId: number): Observable<Product[]> {
    let uri = `${this.BASE_CATALOG_URL}getProductCatalogByCustomer/${customerId}`;
    return this.http.get<Product[]>(uri);
  }

  public getProductCatalogByUserGroup(): Observable<Product[]> {
    let userGroupId = this.authService.GetCurrentUser().currentUserGroupHeaderId;
    let uri = `${this.BASE_CATALOG_URL}GetProductCatalogByUserGroup/${userGroupId}`;

    return this.http.get<Product[]>(uri);
  }

  public getProductItems(productId: number): Observable<any> {
    return this.getCurrentCustomerId().pipe(mergeMap( customerId => {
      let uri = `${this.BASE_CATALOG_URL}GetDetailProductInfo/${productId}?CustomerId=${customerId}`;
      return this.http.get<any>(uri);
    }))
  }

  public getProductItemsPrice(listOfItemsIds: number[]): Observable<any> {
    return this.globalDataService.getProductItemsPrice(listOfItemsIds)
  }

  public getCustomersNameAndShipToId(): Observable<any[]> {

    let params = `customerId in (${[...this.getAllCustomerIds()]})`;
    let uri = `${this.BASE_URL}customer/Customers?$select=customerName,customerId,defaultShipToID &$filter=${params}`;

    return this.http.get<any>(uri).pipe(
      map( x => x.value)
    );
  }

  public getPropertyTranslated(frenchProp: string, englishProp: string): string {
    return this.utilService.getPropertyTranslated(frenchProp, englishProp);
  }

  public initLoad(): Observable<Product[]> {
    return this.getProductCatalogByUserGroup().pipe(
      tap((response: any) => {
        this.products.next(response);
      })
    );
  }

  public initLoadSupervisor(customerId: number =this.authService.GetCurrentUser().currentCustomerId): Observable<Product[]> {
    return this.getProductCatalogByCustomer(customerId).pipe(
      tap((response: Product[]) => {
        this.products.next(response);
      })
    );
  }

  public addToCart(productId: number, itemId: number, quantity: number, price: number, note01: string, isProductOnRequest: boolean) {
    if (this.permissionService.canAddToCartWithoutRestriction()) {
      this.globalDataService.addToCartWithoutRestriction(productId, itemId, quantity, note01, isProductOnRequest);
    }
    else {
      this.globalDataService.addToCart(productId, itemId, quantity, price, note01, isProductOnRequest);
    }
  }

  public navigateToCart(): void {
    this.router.navigate(['/cart']);
  }

  public putDollarSign(price: string): string {
    return this.utilService.putDollarSignByLocal(price);
  }

  public getRemainingQuantity(productId: number): number {
    return this.globalDataService.getRemainingQuantity(productId);
  }

  public canSeePrice(): Observable<boolean> {
    return this.globalDataService.canUserSeeProductPrice();
  }

  public hasMoreThenOneCustomerId(): boolean {
    if (this.authService.GetCurrentUser().ListCatalogEnabledCustomerIds.length > 1) {
      return true;
    }
    else {
      return false;
    }
  }

  public getAllCustomerIds(): number[] {
    return this.authService.GetCurrentUser().ListCatalogEnabledCustomerIds;
  }

  public getCurrentCustomerId() {
    return this.globalDataService.currentCustomerId.asObservable()
  }

  public setCurrentCustomerId(id: number) {
    this.globalDataService.setCurrentCustomerId(id);
  }

  public getListOfAllPossibleFilteringProperties(): string[] {
    return this.globalDataService.getListOfAllPossibleFilteringProperties();
  }

  /**
   * fetch et retourne la liste des catégories
   * @returns la liste des categories utilisées pour filtrer les produits
   */
  public getTopLevelChannelNodes(): Observable<any> {
    let uri = `${this.BASE_URL}Product/ChannelNode?$filter=channelNodeParentNodeKey eq 'products'`
            + `&$select=channelNodeEntityID,channelNodeKey,channelNodeTitleFr,channelNodeTitleEn`;
    return this.http.get<any>(uri).pipe(
      tap(() => {}),
      catchError((err: HttpErrorResponse) => {
        throw err;
      }),
      retry(2)
    );
  }

  public setCustomerShipToId(id: string): void {
    this.globalDataService.setCustomerShipToId(id);
  }

  public clearCart(): void {
    this.globalDataService.clearCart();
  }

  // POPUP PRODUCT ITEM
  public addToCartPopup(data: any): any {
    const confirmObservable = new Observable((observer) => {
      // open dialog
      var dialog = this.dialogService.open({
        content: AddToCartPopupComponent,
        minHeight: 400,
        maxHeight: 800,
        minWidth: 500,
        maxWidth: 900,
      });
      // set default values
      const dialogData = dialog.content.instance as AddToCartPopupComponent;
      dialogData.data = data;
      // subscribe to response
      dialog.result.subscribe((result) => {
        if (result instanceof DialogCloseResult) {
          // cancel
          observer.next(false);
        } else {
          // confirm
          observer.next(result);
        }
      });
    });
    return confirmObservable;
  }

  public checkIfProductIsUnlimited(productId:number): boolean {
    let restrictions = this.globalDataService.productRestrictions.getValue().filter(x => x.productId === productId)

    if(restrictions.length > 0 && restrictions[0].maxQuantity == null) {
      return true
    }
    else {
      return false
    }
  }
}
