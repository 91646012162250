import { Component, OnInit } from '@angular/core';
import { DialogRef, DialogContentBase } from '@progress/kendo-angular-dialog';
import { CatalogService } from '../catalog.service';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/shared/util.service';

@Component({
  selector: 'app-add-to-cart-popup',
  templateUrl: './add-to-cart-popup.component.html',
  styleUrls: ['./add-to-cart-popup.component.scss'],
})
export class AddToCartPopupComponent extends DialogContentBase implements OnInit {
  public data: any;
  isLoading = true;

  constructor(
    private dialogRef: DialogRef,
    private catalogService: CatalogService,
    private router: Router,
    private utilService: UtilService
  ) {
    super(dialogRef);
  }

  ngOnInit(): void {}

  cancel() {
    this.dialogRef.close(false);
  }

  handleAddToCardClickedEvent(data: any) {
    this.dialogRef.close(data);
  }

  public updateToggleIsLoading(isLoading:boolean):void {
    this.isLoading = isLoading;
  }

}
