import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { State, toODataString } from '@progress/kendo-data-query';
import { ProductItemPopupComponent } from './product-item-popup/product-item-popup.component';
import { ProductItem } from 'src/app/module/product/product-item.model';
import { UtilService } from 'src/app/shared/util.service';
import { DialogService, DialogCloseResult } from '@progress/kendo-angular-dialog';
import { DialogRef } from '@progress/kendo-angular-dialog';

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class ProductItemService {
  public productItems: ReplaySubject<ProductItem[]> = new ReplaySubject<ProductItem[]>(1);
  public productItem: ReplaySubject<ProductItem> = new ReplaySubject<ProductItem>(1);
  public productItemsOdata: ReplaySubject<any> = new ReplaySubject<any>(1);
  public dialog!: DialogRef;
  
  constructor(
    private http: HttpClient,
    private utilService: UtilService,
    private dialogService: DialogService,
  ) {}


  list(): Observable<ProductItem[]> {
    return this.http
      .get<ProductItem[]>(apiUrl + 'Product/ProductItems', {})
      .pipe(
        tap((response: any) => {
          this.productItems.next(response);
        })
      );
  }

  read(parentProductID: number): Observable<ProductItem> {
    let paramString = 'parentProductID eq ' + parentProductID;
    /*
    for (const line of _cart) {
      paramString +=`(productItemId eq ${line.itemId}) or`;
    }

      //on enlève un: (espace) or, de trop
//    paramString = paramString.substring(0, paramString.length - 3);

    paramString = 'parentProductID eq ' + parentProductID
    return this.http.get<any>(`${this.BASE_URL}Product/ProductItems?$filter=${paramString}`).pipe(mergeMap((res:any) => {
*/

    return this.http
      .get<any>(`${apiUrl}Product/ProductItems?$filter=${paramString}`, {})
      .pipe(
        tap((response: any) => {
          // conversion des champs date ici?
          if (response!.value) this.productItems.next(response.value);
        })
      );
  }

  read_old(id: number): Observable<ProductItem> {
    return this.http
      .get<ProductItem>(apiUrl + 'Product/ProductItems/' + id, {})
      .pipe(
        tap((response: any) => {
          // conversion des champs date ici?
          this.productItem.next(response);
        })
      );
  }

  create(productitem: any): Observable<any> {
    // todo: potentiel manipulation a faire avec les champs date, checkbox, etc
    return this.http
      .post<any>(apiUrl + 'Product/ProductItems/', productitem)
      .pipe(
        tap((response: any) => {
          //this.productItem.next(response); // TODO: réactiver quand l'API va me retourner le record comme dans un get
          // TODO : refresh de la variable this.productItems avec le nouveau record
        })
      );
  }

  update(productitem: any): Observable<any> {
    // todo: potentiel manipulation a faire avec les champs date, checkbox, etc
    return this.http
      .put<any>(
        apiUrl + 'Product/ProductItems/' + productitem.productItemId,
        productitem
      )
      .pipe(
        tap((response: any) => {
          // this.productItem.next(response); // TODO: réactiver quand l'API va me retourner le record comme dans un get
          // TODO : refresh de la variable this.productItems avec le record a jour
        })
      );
  }

  // ODATA : STATE

  odata(state: State): Observable<any> {
    let _state = this.utilService.makeStateCaseInsensitive(state)
    let uri = `${apiUrl}Product/ProductItems?${toODataString(_state)}&$count=true`
    return this.http.get<any>(uri).pipe(
      tap((response: any) => {
        this.productItemsOdata.next(response);
      })
    );
  }

  // ODATA  : STRING

  odataStr(state: State, oDataString: string): Observable<any> {
    let _state = this.utilService.makeStateCaseInsensitive(state)
    let uri = `${apiUrl}Product/ProductItems?${oDataString}&${toODataString(_state)}&$count=true`
    return this.http.get<any>(uri);
  }

  // POPUP 

  public popup(): any {
    const confirmObservable = new Observable((observer) => {
      // open dialog
      this.dialog = this.dialogService.open({
        content: ProductItemPopupComponent,
        minWidth: 400,
        height: 800,
        maxWidth: 900,
        cssClass: "ss-user-group-dialog-content"
      });
      // set default values
      const dialogData = this.dialog.content.instance as ProductItemPopupComponent;
      dialogData.title = 'module.product.product-item.popup.title';
      dialogData.button_cancel = 'module.product-item.popup.button_cancel';
      // subscribe to response
      this.dialog.result.subscribe((result) => {
        if (result instanceof DialogCloseResult) {
          // cancel
          observer.next(false);
        } else {
          // confirm
          observer.next(result);
        }
      });
    });
    return confirmObservable;
  }
}
