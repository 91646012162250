import { Component, OnInit } from '@angular/core';
import { Subject, takeUntil, tap } from 'rxjs';
import { AuthUser } from 'src/app/core/auth/auth-user.model';
import { AuthService } from 'src/app/core/auth/auth.service';
import { LocalStorage } from 'src/app/core/local-storage.enum';
import { UtilService } from 'src/app/shared/util.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  expanded = false;

  private unsubNotifier = new Subject<void>();
  constructor(public authService: AuthService, private utilService: UtilService) {


    //LOG_BASE_LifeCycle - this.utilService.logInformation(`LayoutComponent->constructor`);

    // Expanded default
    var expandedStr = localStorage.getItem(LocalStorage.SIDENAV_EXPANDED);
    this.expanded = expandedStr == null || expandedStr == 'true';

    //on va ÉCOUTER le changement d'uysager en cours, et déclencher un refresh au besoin. Le déclenchement se fait dans authService, via une méthode qui fait un next sur le currentAuthUserBehavior
    this.authService.currentAuthUserBehavior
    .pipe(
      //si on veut faire quelquechose apres avoir reçu un trigger de changement d'usager..
      tap((x) => {
        //LOG_BASE_LifeCycle - this.utilService.logInformation(`LayoutComponent->currentAuthUserBehavior Changed -> UserData:${this.currentUser().GetDebugUserInfo()}`);
      })
      ,takeUntil(this.unsubNotifier)
      )
    .subscribe();
  }

  ngOnInit(): void {
      //LOG_BASE_LifeCycle - this.utilService.logInformation(`LayoutComponent->ngOnInit - UserData:${this.currentUser().GetDebugUserInfo()}`);

  }

  ngOnDestroy(): void {
    //LOG_BASE_LifeCycle - this.utilService.logInformation(`LayoutComponent->ngOnDestroy`);
    this.unsubNotifier.next();
    this.unsubNotifier.unsubscribe();
  }
  onExpandedChange(e: any) {
    localStorage.setItem(LocalStorage.SIDENAV_EXPANDED, e.toString());
  }

  /** Cette méthode permet d'aller chercher la version 'live' de l'usager connecté (pas via un subscription).  Pratique pour tester localement 'IsLogged... avoir le nom/prénom, etc,  sans avoir à lier le TemplateHTML à authhService
   * @returns
   */
  public currentUser(): AuthUser {return this.authService.GetCurrentUser()};

}
