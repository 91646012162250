import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,  } from '@angular/common/http';
import { ReplaySubject, Observable, of } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { State, toODataString } from '@progress/kendo-data-query';
import { DialogService, DialogRef, DialogCloseResult } from '@progress/kendo-angular-dialog';
import { saveAs } from 'file-saver';

import { UserGroup } from './user-group.model';
import { CustomerAddress } from '../checkout/customer-adress.model';
import { UserGroupPopupComponent } from './user-group-popup/user-group-popup.component';
import { UtilService } from 'src/app/shared/util.service';
import { UserGroupImportComponent } from './user-group-import/user-group-import.component';
import { LocalStorage } from 'src/app/core/local-storage.enum';

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class UserGroupService {
  private userGroups: ReplaySubject<UserGroup[]> = new ReplaySubject<UserGroup[]>(1);
  public userGroup: ReplaySubject<UserGroup> = new ReplaySubject<UserGroup>(1);
  public userGroupsOdata: ReplaySubject<any> = new ReplaySubject<any>(1);
  public userGroupShipToAddresses: ReplaySubject<CustomerAddress[]> = new ReplaySubject<CustomerAddress[]>(1);
  private dialog!: DialogRef;

  constructor(
    private http: HttpClient,
    private dialogService: DialogService,
    private utilService: UtilService
  ) { }

  initOne() {
    this.userGroup.next(new UserGroup());
  }

  list(): Observable<UserGroup[]> {
    let uri = `${apiUrl}Customer/UserGroupHeader`
    return this.http.get<UserGroup[]>(uri).pipe(
      mergeMap((response: any) => {
        let value = response.value
        this.userGroups.next(value);
        return of(value)
      })
    );
  }

  read(id: number): Observable<UserGroup> {
    return this.http
      .get<UserGroup>(apiUrl + 'Customer/UserGroupHeader/' + id, {})
      .pipe(
        tap((response: any) => {
          // conversion des champs date de STRING à DATE, (checkbox???)
          this.userGroup.next(response);
        })
      );
  }

  create(userGroup: any): Observable<any> {
    // todo: potentiel manipulation a faire avec les champs date, checkbox, etc
    return this.http
      .post<any>(apiUrl + 'Customer/UserGroupHeader', userGroup)
      .pipe(
        tap((response: any) => {
          this.userGroup.next(response);
        })
      );
  }

  update(userGroup: UserGroup): Observable<any> {
    // todo: potentiel manipulation a faire avec les champs date, checkbox, etc
    return this.http
      .put<any>(
        apiUrl + 'Customer/UserGroupHeader/' + userGroup.userGroupHeaderId,
        userGroup
      )
      .pipe(
        tap((response: any) => {
          // this.userGroup.next(response); // TODO: réactiver quand l'API va me retourner le record comme dans un get
          // TODO : refresh de la variable this.userGroups avec le record a jour
        })
      );
  }

  // CACHE

  listCache(forceFetch?: boolean): Observable<UserGroup[]> {
    let fetch = false;
    // read localStorage
    let ls = localStorage.getItem(LocalStorage.CACHE_USER_GROUP);
    if (forceFetch) {
      fetch = true;
    }
    else if(ls != null && ls.length > 0) {
      let data = JSON.parse(ls!);
      let now = Date.now();
      // force to refresh every X seconds
      if (now - data.lastUpdate > 20000) {
        fetch = true;
      }
    }
    else {
      // empty localstorage
      fetch = true;
    }
    // fetch or return cache data
    if (fetch) {
      let uri = `${apiUrl}customer/UserGroupHeader`
      return this.http.get<UserGroup[]>(uri).pipe(
        mergeMap((response: any) => {
          let value = response.value
          let newCache = {
            lastUpdate : Date.now(),
            data : value
          };
          localStorage.setItem(LocalStorage.CACHE_USER_GROUP, JSON.stringify(newCache));
          return of(value)
        })
      );
    }
    else {
      let data = JSON.parse(ls!);
      return of(data.data);
    }
  }

  // ODATA : STATE

  odata(state: State): Observable<any> {
    let _state = this.utilService.makeStateCaseInsensitive(state);
    let customerExpand = `&$expand=customer($select=customerId,customerName)`;
    let uri = `${apiUrl}Customer/UserGroupHeader?${toODataString(_state) + customerExpand}&$count=true`;
    return this.http.get<UserGroup>(uri).pipe(
      tap((response: any) => {
        this.userGroupsOdata.next(response);
      })
    );
  }

  // ODATA  : STRING

  odataStr(state: State, oDataString: string): Observable<any> {
    let oDataFullString = this.utilService.getODataFullString(state, oDataString);
    // fetch
    return this.http
      .get<any>(
        apiUrl + `Customer/UserGroupHeader?${oDataFullString}&$count=true`,
        {}
      )
      .pipe(
        tap((response: any) => {
          this.userGroupsOdata.next(response);
        })
      );
  }

  odataEndpointlist(odataParams: string = ''): Observable<any[]> {
    let uri = `${apiUrl}Customer/UserGroupHeader${odataParams}`
    return this.http.get<any[]>(uri).pipe(
      mergeMap((response: any) => {
        return of(response.value)
      })
    );
  }

  public getShipToAddresses(customerID: number): Observable<CustomerAddress[]> {
    return this.http
      .get<any>(
        `${apiUrl}Customer/CustomerShipto?$filter=CustomerId eq ${customerID}`
      )
      .pipe(
        mergeMap((response: any) => {
          let addresses: CustomerAddress[] = response.value;
          this.userGroupShipToAddresses.next(addresses);
          return of(addresses);
        })
      );
  }

  // POPUP USER GROUP LIST

  public popup(): any {
    const confirmObservable = new Observable((observer) => {
      // open dialog
      this.dialog = this.dialogService.open({
        content: UserGroupPopupComponent,
        minWidth: 400,
        height: 800,
        maxWidth: 900,
        cssClass: "ss-user-group-dialog-content"
      });
      // set default values
      const dialogData = this.dialog.content.instance as UserGroupPopupComponent;
      dialogData.title = 'module.user-group.title';
      dialogData.button_cancel = 'module.user-group.popup.button_cancel';
      // subscribe to response
      this.dialog.result.subscribe((result) => {
        if (result instanceof DialogCloseResult) {
          // cancel
          observer.next(false);
        } else {
          // confirm
          observer.next(result);
        }
      });
    });
    return confirmObservable;
  }

  // IMPORT / EXPORT PRODUCT

  public import(userGroupHeaderId: number, userGroupDescription: string): any {
    const confirmObservable = new Observable((observer) => {
      let dialog: DialogRef;
      // open dialog
      dialog = this.dialogService.open({
        content: UserGroupImportComponent,
      });
      // set default values
      const dialogData = dialog.content.instance as UserGroupImportComponent;
      dialogData.userGroupDescription = userGroupDescription;
      // subscribe to response
      dialog.result.subscribe((result) => {
        if (result instanceof DialogCloseResult) {
          // cancel
          observer.next(false);
        } else {
          // confirm : send file
          const formData = new FormData();
          formData.append('file', dialogData.file);
          this.http
            .post<any>(apiUrl + `Customer/UserGroupHeader/${userGroupHeaderId}/ImportUserGroupDataFromExcel`, formData)
            .subscribe((response) => {
              // notify
              this.utilService.notify(
                'module.user-group.import.success_import',
                'success'
              );
              observer.next(response);
            });
         }
      });
    });
    return confirmObservable;
  }

  export(userGroupHeaderId: number) {
    // permet content différent de JSON
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      responseType: 'blob' as 'json',
    };
    this.http
      .get<any>(apiUrl + `Customer/UserGroupHeader/${userGroupHeaderId}/ExportUserGroupDataToExcel`, httpOptions)
      .subscribe((response: any) => {
        let yourDate = new Date()
        let date = yourDate.toISOString().split('T')[0]
        let filename = date + '_Export_produit_groupe_usager_' + userGroupHeaderId + '.xlsx';
        saveAs(response, filename)
      });
  }

}
