import { Pipe, PipeTransform } from '@angular/core';
/**
 * Retourne information sur le Customer attaché à un usager, via le système d'assignation de droits
 * Prend l'usager qui doit avoir le expand sur userrightAssignation
 *  OU
 * Prend le userId
 * Usage:
 *   usager  | getCustomerInfo : listDeTousLesCustomerPossible
 * Example:
*/
@Pipe({
  name: 'getCustomerInfo'
})
export class CustomerDescriptionPipe implements PipeTransform {
  transform(value: any, customerList: any): string {
    if (!customerList) return '';
    let customerId = 0;
    let description = '';
    if (typeof value == 'number')
      customerId = value;
    else {
      // trouver le customerId dans le droit du role client-employe
      if (value && value.userRightAssignations && value.userRightAssignations.length >= 0) {
        let rightEmployee = value.userRightAssignations.find((ur: any) => ur.roleId == '96041a3c-8bec-4cac-aba5-fd3d1eec9c69');
        if (rightEmployee) {
          customerId = rightEmployee.assignedCustomerId;
        }
      }
    }
    // find customer name
    if (customerId != 0) {
      let customer = customerList.filter((c: any) => c.customerId == customerId)
      if (customer && customer.length >= 0) {
        description = customerId + ' - ' + customer[0].customerName
      }
    }    
    return description;
  }
}