export class CartLine {

  public index:number;
  public productId:number;
  public itemId:number;
  public quantity:number;
  public note01:string;
  public isProductOnRequest:boolean;

  constructor(index:number, productId:number, itemId:number, quantity:number, note01:string, isProductOnRequest:boolean) {
    this.index = index;
    this.productId = productId;
    this.itemId = itemId;
    this.quantity = quantity;
    this.note01 = note01;
    this.isProductOnRequest = isProductOnRequest;
  }
}
