import { Pipe, PipeTransform } from '@angular/core';
/**
 * Retourne information sur le UserGroup attaché à un usager, via le système d'assignation de droits
 * Prend l'usager qui doit avoir le expand sur userrightAssignation
 *  OU
 * Prend le userId
 * Usage:
 *   usager  | getUserGroupInfo : listDeTousLesUserGroupPossible
 * Example:
*/
@Pipe({
  name: 'getUserGroupInfo'
})
export class UserGroupDescriptionPipe implements PipeTransform {
  transform(value: any, userGroupList: any): string {
    if (!userGroupList) return '';
    let userGroupId = 0;
    let description = '';
    if (typeof value == 'number')
      userGroupId = value;
    else {
      // trouver le userGroupId dans le droit du role client-employe
      if (value && value.userRightAssignations && value.userRightAssignations.length >= 0) {
        let rightEmployee = value.userRightAssignations.find((ur: any) => ur.roleId == '96041a3c-8bec-4cac-aba5-fd3d1eec9c69');
        if (rightEmployee) {
          userGroupId = rightEmployee.assignedUserGroupId;
        }
      }
    }
    // chercher la description dans la liste des user group
    if (userGroupId != 0) {
      let userGroup = userGroupList.find((ug: any) => ug.userGroupHeaderId == userGroupId);
      if (userGroup) {
        description = userGroupId + ' - ' + userGroup.userGroupDescription
      }
    }
    return description;
  }
}
