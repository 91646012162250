import { Injectable, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CldrIntlService, IntlService } from "@progress/kendo-angular-intl";
import { LocalStorage } from '../local-storage.enum';
import { MessageService } from "@progress/kendo-angular-l10n";

@Injectable({
  providedIn: 'root',
})

export class TranslationManagementService extends MessageService {

    @Output() onLangChange = this.translateService.onLangChange

    //https://www.telerik.com/kendo-angular-ui/components/globalization/localization/messages/
    //https://www.telerik.com/kendo-angular-ui/components/grid/api/CustomMessagesComponent/#toc-filter
    private translationData:any = {
        "fr-CA": {
            rtl: false,
            messages: {
                "kendo.grid.columnsApply": "Appliquer",
                "kendo.grid.columnsReset": "réinitialiser",
                "kendo.grid.detailCollapse": "réduire",
                "kendo.grid.detailExpand": "aggrandir",
                "kendo.grid.filter": "filtrer",
                "kendo.grid.filterAfterOperator": "Après",
                "kendo.grid.filterAfterOrEqualOperator": "Après ou égale",
                "kendo.grid.filterAndLogic": "Et",
                "kendo.grid.filterOrLogic": "Ou",
                "kendo.grid.filterBeforeOperator": "Avant",
                "kendo.grid.filterBeforeOrEqualOperator": "Avant ou égale",
                "kendo.grid.filterBooleanAll": "Tous",
                "kendo.grid.filterClearButton" : "Vider",
                "kendo.grid.filterContainsOperator": "Contient",
                "kendo.grid.filterDateToday": "Aujourd'hui",
                "kendo.grid.filterDateToggle": "basculer",
                "kendo.grid.filterEndsWithOperator": "Se terminer par",
                "kendo.grid.filterEqOperator": "Égale",
                "kendo.grid.filterFilterButton": "Filtrer",
                "kendo.grid.filterGteOperator": "Plus grand ou égale",
                "kendo.grid.filterGtOperator": "Plus grand",
                "kendo.grid.filterIsEmptyOperator": "Est vide",
                "kendo.grid.filterIsFalse": "Non",
                "kendo.grid.filterIsNotEmptyOperator": "N'est pas vide",
                "kendo.grid.filterIsNotNullOperator": "Non nulle",
                "kendo.grid.filterIsNullOperator": "Nulle",
                "kendo.grid.filterIsTrue": "Oui",
                "kendo.grid.filterLteOperator": "Plus petit ou égale",
                "kendo.grid.filterLtOperator": "Plus petit",
                "kendo.grid.filterNotContainsOperator": "Ne contient pas",
                "kendo.grid.filterNotEqOperator": "N'est pas égale",
                "kendo.grid.filterNumericDecrement": "Baisser",
                "kendo.grid.filterNumericIncrement": "Monter",
                "kendo.grid.filterStartsWithOperator" : "Commence par",
                "kendo.grid.groupCollapse" : "groupe réduire",
                "kendo.grid.groupExpand" : "groupe agrandir",
                "kendo.grid.groupPanelEmpty" : "Vide",
                "kendo.grid.noRecords": "Aucun enregistrement disponible",
                "kendo.grid.pagerFirstPage" : "Première page",
                "kendo.grid.pagerItems" : "items",
                "kendo.grid.pagerItemsPerPage" : "",
                "kendo.grid.pagerLastPage" : "Dernière page",
                "kendo.grid.pagerNextPage" : "Prochaine page",
                "kendo.grid.pagerOf" : "de",
                "kendo.grid.pagerPage" : "page",
                "kendo.grid.pagerPageNumberInputTitle" : "",
                "kendo.grid.pagerPreviousPage" : "Page précédente",
                "kendo.grid.selectAllCheckboxLabel" : "Tous",
                "kendo.grid.selectionCheckboxLabel" : "Sélection",
                "kendo.grid.setColumnPosition" : "",
                "kendo.grid.sortable" : "triable",
                "kendo.grid.sortAscending" : "Croissant",
                "kendo.grid.sortDescending" : "Décroissant",
                "kendo.grid.sortedAscending" : "Trié par ordre croissant",
                "kendo.grid.sortedDescending" : "Trié par ordre décroissant",
                "kendo.grid.sortedDefault" : "Non trié",
                "kendo.grid.stick" : "collé",
                "kendo.grid.unlock" : "débloqué",
                "kendo.grid.unstick" : "décollé"
            }
        },
        "en-US": {
            rtl: false,
            messages: {}
        }
    }

    constructor(
        private translateService:TranslateService,
        private intlService: IntlService
    ) {
        super()
        this.translateService.onLangChange.subscribe( (lang:any) => {
            let kendoLocale = lang.lang === 'en' ? 'en-US' : 'fr-CA';
            (<CldrIntlService>this.intlService).localeId = kendoLocale;
            this.notify();
            localStorage.setItem(LocalStorage.LANG, lang.lang)
        })
    }

    public changeCurrentLang(lang:string):void {
        this.translateService.use(lang);
    }

    public getCurrentLang():string {
        return this.translateService.currentLang
    }

    private get messages(): any {
        let localId = (<CldrIntlService>this.intlService).localeId

        const lang = this.translationData[localId];

        return lang.messages;

    }

    public override get(key: string): string {
        let msg:string = this.messages[key]
        return msg
    }

    public formatDateToLocale(_date:Date) {
        return this.intlService.formatDate(_date)
    }

    /**
     * Permet de retourner une string TRADUITE, et prend des params interpolé (vient avec Ng-Translate)
     * @param key test
     * @param interpolateParams  peut etre VIDE.  permet de mapper des valeurs.  dans ma string, si j'ai "allo j'ai {{age}} ans".  je devrai passer :  translateKey('model.monage',{age:13});
     * @returns
     */
    public translateKey(key:string, interpolateParams:Object={}): string {
      return this.translateService.instant(key,interpolateParams);
   }

}
