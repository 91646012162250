import { Injectable } from '@angular/core';
import { HttpRequest,HttpHandler,HttpEvent,HttpInterceptor,HttpResponse,HttpErrorResponse,HttpStatusCode} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ErrorHandlerHttp } from 'src/app/core/error/error-handler-http.model';
import { DialogService, DialogCloseResult } from '@progress/kendo-angular-dialog';

import { AuthService } from 'src/app/core/auth/auth.service';
import { PopAlertComponent } from 'src/app/shared/component/pop-alert/pop-alert.component';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  dialogOpened = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private dialogService: DialogService, ) {}

  intercept(request: HttpRequest<any>,next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
        }
        return event;
      }),
      catchError((errorResponse: HttpErrorResponse) => {

        console.log('error interceptor : catch error');
        console.log(errorResponse);

        let httpError = {
          key: '',
          errorTitle: '',
          errorMessage: '',
          fullHttpErrorReponse: errorResponse,
          statusCode: errorResponse.status,
        } as ErrorHandlerHttp;

        switch (errorResponse.status) {
          case HttpStatusCode.Ok:
          case HttpStatusCode.NoContent:
          case HttpStatusCode.NotModified: {
            // exécution réussie
            break;
          }
          case HttpStatusCode.Unauthorized: {
            httpError.key = 'error.HTTP_STATUT_UNAUTHORIZED';
            if (
              errorResponse.error &&
              errorResponse.error.errorCode == 'USER_MUST_CHANGE_PASSWORD'
            ) {
              this.router.navigate([
                '/auth/reset-password',
                {
                  changePasswordToken: errorResponse.error.changePasswordToken,
                  userId: errorResponse.error.userId,
                },
              ]);
            } else if (errorResponse.error && errorResponse.error.title) {
              this.router.navigate(['/auth']);
            } else {
              this.authService.resetCurrentUserData();
              this.alertUnauthorized();
              this.router.navigate(['/auth']);
            }
            break;
          }
          case HttpStatusCode.Conflict: {
            httpError.key = 'error.HTTP_STATUT_CONFLICT';
            break;
          }
          case HttpStatusCode.InternalServerError: {
            if (this.router.url != '/auth')
              httpError.key = 'error.HTTP_STATUT_INTERNALSERVERERROR';
            break;
          }
          case HttpStatusCode.Forbidden: {
            httpError.key = 'error.HTTP_STATUT_FORBIDDEN';
            break;
          }
          case HttpStatusCode.ServiceUnavailable: {
            httpError.key = 'error.HTTP_STATUT_SERVICEUNAVAILABLE';
            break;
          }
          case HttpStatusCode.NotFound: {
            httpError.key = 'error.HTTP_STATUT_NOTFOUND';
            break;
          }
          case HttpStatusCode.BadRequest: {
            httpError.key = 'error.HTTP_STATUT_BADREQUEST';
            if (errorResponse.error && errorResponse.error.title == 'SSO_LOGIN_FAILED') {
              this.router.navigate(['/auth']);
            }
            break;
          }
          case 0: {
            httpError.key = 'error.HTTP_STATUT_0';
            break;
          }
          default: {
            httpError.key = 'error.HTTP_STATUT_UNKNOWN';
            break;
          }
        }

        // rechercher un code dans la reponse
        if (errorResponse.error && errorResponse.error.title) {
          let title = errorResponse.error.title;
          httpError.key = 'error.' + title;
          if (title == 'VALIDATION_ERROR_INVALID_DATA') {
            let validationErrors = errorResponse.error.errors.VALIDATION_ERROR_INVALID_DATA;
            if (validationErrors.length > 0)
              httpError.key = 'error.' + validationErrors[0].split('|')[0];
            this.alertError("error.VALIDATION_ERROR_INVALID_DATA", validationErrors)
          }
        }

        return throwError(() => httpError);
      })
    );
  }

  // ALERT POPUP

  alertUnauthorized() {
    if (!this.dialogOpened) {
      this.dialogOpened = true;
      let dialogRef = this.dialogService.open({
        content: PopAlertComponent,
        minWidth: 400,
        maxWidth: 700,
        cssClass: null
      });
      const dialogData = dialogRef.content.instance as PopAlertComponent;
      dialogData.title = 'alert.popup_unauthorized.title';
      dialogData.message = 'alert.popup_unauthorized.message';
      dialogRef.result.subscribe((result: any) => {
        this.dialogOpened = false;
        this.router.navigate(['/auth']);
        return result instanceof DialogCloseResult;
      });
    }
  }

  alertError(title: string, errors: any) {
    if (!this.dialogOpened) {
      this.dialogOpened = true;
      // prepare message
      let message = "";
      errors.forEach((error: string) => {
        message += "<p>" + error + "</p>";
      });
      // open dialog
      let dialogRef = this.dialogService.open({
        content: PopAlertComponent,
        minWidth: 500,
        maxWidth: 700,
        maxHeight: 600,
        cssClass: null
      });
      const dialogData = dialogRef.content.instance as PopAlertComponent;
      dialogData.title = title;
      dialogData.message = "<div class='ss-pop-alert-error-container'>" + message + "</div>";
      dialogRef.result.subscribe((result: any) => {
        this.dialogOpened = false;
        return result instanceof DialogCloseResult;
      });
    }
  }

}
