import { Pipe, PipeTransform } from '@angular/core';
/**
 * Retourne la quantité expédiée totale pour un produit
 * @param productItemNumber : numéro du produit a recherche dans le data
 * @param dataJson : data contenant les info de shipping (suffix)
*/
@Pipe({
  name: 'getQuantityShipped'
})
export class OrderDetailQuantityShippedPipe implements PipeTransform {
  transform(productItemNumber: string, dataJson: any): number {
    let qtytotal = 0;
    // loop et faire la somme des quantité du produit spécifiés
    if (dataJson?.myShippingLineInfo) {
      dataJson.myShippingLineInfo.forEach((line: any) => {
        if (line.detailProductItemNumber === productItemNumber) {
          qtytotal += line.detailQuantityShipped;
        }
      });
    }
    return qtytotal;
  }
}