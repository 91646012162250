import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from 'src/app/module/user/user.model';
import { AuthService } from 'src/app/core/auth/auth.service';
import { LocalStorage } from 'src/app/core/local-storage.enum';
import { permissionCodeEnum } from 'src/app/core/permission-code.enum';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(
    private authService: AuthService
  ) { }

  // SIDENAV

  // affiche les information de l'usager dans le Sidenav
  public canSeeSidenavUserInfo(user?: User): boolean {
    return true;
    //return this.authService.GetCurrentUser().userRole !== 'GLOBAL_ADMIN'
  }

  // afficher le lien CLIENT dans le Sidenav ET autorise la route dans le guard
  public canSeeSidenavCustomer(user?: User): boolean {
    return this.TestSimpleClaimExistance(permissionCodeEnum.PRIVILEGE_CUSTOMER_MODIFY);
  //  return this.authService.GetCurrentUser().userRole == 'GLOBAL_ADMIN'
  }

  // afficher le lien GROUPE USAGER dans le Sidenav
  public canSeeSidenavUserGroup(user?: User): boolean {
    return this.TestSimpleClaimExistance(permissionCodeEnum.PRIVILEGE_USERGROUP_MODIFY);
//    return this.authService.GetCurrentUser().userRole == 'GLOBAL_ADMIN' || this.authService.GetCurrentUser().userRole == 'SUPERVISEUR';
  }

  // afficher le lien RAPPORT dans le Sidenav
  public canSeeSidenavReport(user?: User): boolean {
    return this.TestSimpleClaimExistance(permissionCodeEnum.PRIVILEGE_ORDER_CANVIEWREPORTS);
    //    return this.authService.GetCurrentUser().userRole == 'GLOBAL_ADMIN' || this.authService.GetCurrentUser().userRole == 'SUPERVISEUR';
  }

// afficher le lien RAPPORT dans le Sidenav
public canSeeSidenavAuditLogs(user?: User): boolean {
  return this.TestSimpleClaimExistance(permissionCodeEnum.PRIVILEGE_SYSTEM_ADMIN);
}
// afficher le lien RAPPORT dans le Sidenav
public canSeeSystemSideNav(user?: User): boolean {
  return this.TestSimpleClaimExistance(permissionCodeEnum.PRIVILEGE_SYSTEM_ADMIN);
}

  // afficher le lien COMMANDE dans le Sidenav
  public canSeeSidenavOrder(user?: User): boolean {
    return this.TestSimpleClaimExistance(permissionCodeEnum.PRIVILEGE_ORDER_CANVIEWORDERHISTORY);
    //    return this.authService.GetCurrentUser().userRole == 'GLOBAL_ADMIN' || this.authService.GetCurrentUser().userRole == 'SUPERVISEUR' || this.authService.GetCurrentUser().currentUserIdClientEmploye;
  }

  // afficher le lien USAGER dans le Sidenav
  public canSeeSidenavUser(user?: User): boolean {
    return this.TestSimpleClaimExistance(permissionCodeEnum.PRIVILEGE_USER_MODIFY);
//    return this.authService.GetCurrentUser().userRole == 'GLOBAL_ADMIN' || this.authService.GetCurrentUser().userRole == 'SUPERVISEUR';
  }

  // afficher le lien PRODUIT dans le Sidenav
  public canSeeSidenavProduct(user?: User): boolean {
    return this.TestSimpleClaimExistance(permissionCodeEnum.PRIVILEGE_PRODUCT_MODIFY);
  //  return this.authService.GetCurrentUser().userRole == 'GLOBAL_ADMIN'
  }

  // afficher le lien CATALOG dans le Sidenav
  public canSeeSidenavCatalog(user?: User): boolean {
    return this.TestSimpleClaimExistance(permissionCodeEnum.PRIVILEGE_ORDER_CANBROWSECATALOG);
  //  return this.authService.GetCurrentUser().userRole !== 'GLOBAL_ADMIN'
}

  // GLOBAL

  // afficher le composant "Commencer mes achats" sur la page Accueil
  public canGoToCatalogFromHome(user?: User): boolean {
    return this.TestSimpleClaimExistance(permissionCodeEnum.PRIVILEGE_ORDER_CANBROWSECATALOG);
    //    return this.authService.GetCurrentUser().userRole !== 'GLOBAL_ADMIN';
  }

  // afficher "Montant restant à dépenser" sur la page d'Accueil, "Solde restant" dans le Cart et "Solde restant pour vos achats" dans l'en-tête.
  public canSeeBudget(user?: User): boolean {
    return this.authService.GetCurrentUser().currentUserIdClientEmploye;
  }

  // load le global data pour les usagers qui peuvent magasiner
  public canLoadShopperGlobalData(user?: User): boolean {
    return this.TestSimpleClaimExistance(permissionCodeEnum.PRIVILEGE_ORDER_CANBROWSECATALOG);
//    return this.authService.GetCurrentUser().currentUserIdClientEmploye || this.authService.GetCurrentUser().userRole == 'SUPERVISEUR';
  }

  // HEADER

  // afficher le bouton Cart dans l'en-tête
  public canSeeCartButton(user?: User): boolean {
    return this.TestSimpleClaimExistance(permissionCodeEnum.PRIVILEGE_ORDER_CANBROWSECATALOG);
//    return this.authService.GetCurrentUser().userRole != 'GLOBAL_ADMIN';
  }

  // CUSTOMER

  // permet d'importer et d'exporter les produits pour un client
  public canImportExportCustomerProduct(user?: User): boolean {
    return this.TestSimpleClaimExistance(permissionCodeEnum.PRIVILEGE_CUSTOMER_ADMIN);
  }

  /**
   * permet d'importer et d'exporter les produits pour un client -
   * <br/>
   * DROIT : PRIVILEGE_CUSTOMER_ADMIN
   * @param user
   * @returns
   */
  public canRefreshInforCSDCustomerData(user?: User): boolean {
    return this.TestSimpleClaimExistance(permissionCodeEnum.PRIVILEGE_CUSTOMER_ADMIN);
  }

  // USER

  // permet de modifier tous les champs d'exception dans USER-EDIT (overrideAccessDateStart, overrideAccessDateEnd, overrideEmployeMinimumAmountToSkipTransportFee, overrideEmployeTransportFeeAmount)
  public canEditUserOverride(user?: User): boolean {
    return this.TestSimpleClaimExistance(permissionCodeEnum.PRIVILEGE_USER_ADMIN) || this.TestSimpleClaimExistance(permissionCodeEnum.PRIVILEGE_USER_MODIFY);
    //return this.authService.GetCurrentUser().userRole == 'GLOBAL_ADMIN';
  }

  // force l'affichage des usagers de type employee uniquement dans USER-EDIT
  public forceUserEditTypeEmployee(user?: User): boolean {
    return !this.TestSimpleClaimExistance(permissionCodeEnum.PRIVILEGE_USER_ADMIN);
    //return this.authService.GetCurrentUser().userRole == 'SUPERVISEUR';
  }

  // permet de changer le mot de passe des usagers de type Superviseur
  public canChangePasswordSupervisor(user?: User): boolean {
    return this.TestSimpleClaimExistance(permissionCodeEnum.PRIVILEGE_USER_ADMIN);
//    return this.authService.GetCurrentUser().userRole == 'GLOBAL_ADMIN';
  }

  // permet de changer le mot de passe des usagers de type Superviseur
  public IsUserAdmin(user?: User): boolean {
    return this.TestSimpleClaimExistance(permissionCodeEnum.PRIVILEGE_USER_ADMIN);
//    return this.authService.GetCurrentUser().userRole == 'GLOBAL_ADMIN';
  }
  // permet de changer le mot de passe des usagers de type Employee
  public canChangePasswordEmployee(user?: User): boolean {
    return this.TestSimpleClaimExistance(permissionCodeEnum.PRIVILEGE_USER_MODIFY);
    //return this.authService.GetCurrentUser().userRole == 'GLOBAL_ADMIN' || this.authService.GetCurrentUser().userRole == 'SUPERVISEUR';
  }

  // permet de modifier les droits ssager
  public canEditUserRight(user?: User): boolean {
    return this.TestSimpleClaimExistance(permissionCodeEnum.PRIVILEGE_USER_ADMIN);
  }

  // USER (PROFILE)

   // À VOIR : ce privilège n'en est pas un, il dit simplement qu'on doit spécifier une adresse email pour les utilisateurs qui ne sont pas CLIENT_EMPLOYE
   // profile.component   => this.authService.GetCurrentUser().currentUserIdClientEmploye

  public isEmailRequired(user?: User): boolean {
    return !(this.authService.GetCurrentUser().currentUserIdClientEmploye);
  }

  // USER GROUP

  // permet d'ajouter un group usager
  public canAddUserGroup(user?: User): boolean {
    return this.TestSimpleClaimExistance(permissionCodeEnum.PRIVILEGE_USERGROUP_ADMIN);
  //  return this.authService.GetCurrentUser().userRole == 'GLOBAL_ADMIN'
  }

  // permet de modifier les champs "avancés" pour un groupe d'usagers (assignedSalesRepEmail, dateStart, dateStop, maximumAllowedAmount, minimumAmountToSkipTransportFee, transportFeeAmount, mustHidePrices)
  public canEditUserGroupAdvancedField(user?: User): boolean {
    return this.TestSimpleClaimExistance(permissionCodeEnum.PRIVILEGE_USERGROUP_ADMIN);
  //  return this.authService.GetCurrentUser().userRole == 'GLOBAL_ADMIN'
  }

  // USER GROUP PRODUCT

  // permet d'ajouter un produit a un groupe usager
  public canAddUserGroupProduct(user?: User): boolean {
    return this.TestSimpleClaimExistance(permissionCodeEnum.PRIVILEGE_USERGROUP_ADMIN);
  //  return this.authService.GetCurrentUser().userRole == 'GLOBAL_ADMIN'
  }

  // permet de modifier un produit d'un groupe usager
  public canEditUserGroupProduct(user?: User): boolean {
    return this.TestSimpleClaimExistance(permissionCodeEnum.PRIVILEGE_USERGROUP_ADMIN);
  //  return this.authService.GetCurrentUser().userRole == 'GLOBAL_ADMIN'
  }

  // permet de détruire un produit d'un groupe usager
  public canDeleteUserGroupProduct(user?: User): boolean {
    return this.TestSimpleClaimExistance(permissionCodeEnum.PRIVILEGE_USERGROUP_ADMIN);
  //  return this.authService.GetCurrentUser().userRole == 'GLOBAL_ADMIN'
  }

  // permet d'importer et d'exporter les produits pour un groupe usager
  public canImportExportUserGroupProduct(user?: User): boolean {
    return this.TestSimpleClaimExistance(permissionCodeEnum.PRIVILEGE_USERGROUP_ADMIN);
  }

  // CATALOG

  // charge en mémoire le catalog du superviseur (catalog.resolver)
  public canLoadSupervisorCatalog(user?: User): boolean {
    return this.authService.GetCurrentUser().currentUserIdClientEmploye === false;
  }

  // permet d'ajouter un produit au Cart sans restriction
  public canAddToCartWithoutRestriction(user?: User): boolean {
    return this.authService.GetCurrentUser().currentUserIdClientEmploye === false;
//    return this.authService.GetCurrentUser().userRole == 'SUPERVISEUR';
  }

  // charge en mémoire la liste des produits pouvant être acheté basé sur le UserGroup
  public canGetProductCatalogByUserGroup(user?: User): boolean {
    return this.authService.GetCurrentUser().currentUserIdClientEmploye;
  }

  // charge en mémoire la liste des produits pouvant être acheté basé sur le Client
  public canGetProductCatalogByCustomer(user?: User): boolean {
      return this.authService.GetCurrentUser().currentUserIdClientEmploye === false;
//    return this.authService.GetCurrentUser().userRole === 'SUPERVISEUR';
}

  // force l'utilisation du ship Id du user group pour l'obtention des prix des produits items
  public forceUserGroupRestrictionsDefaultShipId(user?: User): boolean {
    return this.authService.GetCurrentUser().currentUserIdClientEmploye;
  }

  // CATALOG LIST

  // affiche la quantité restante dans la liste de produit du catalogue
  public canSeeRemainingQuantity(user?: User): boolean {
    return this.authService.GetCurrentUser().currentUserIdClientEmploye;
  }

  // ORDER

  // affiche erpCreationErpDocumentNumber dans ORDER-EDIT
  public canSeeOrderErpInfo(user?: User): boolean {
    return this.TestSimpleClaimExistance(permissionCodeEnum.PRIVILEGE_SYSTEM_ADMIN);
    //return this.authService.GetCurrentUser().userRole == 'GLOBAL_ADMIN';
  }

  // affiche les noms de groupes de produits dans CATALOG-LIST
  public mustHaveProductRestrictions(user?: User): boolean {
    return this.authService.GetCurrentUser().currentUserIdClientEmploye;
  }

  // permet de modifier une commande
  public canEditOrder(user?: User): boolean {
    return this.authService.GetCurrentUser().currentUserIdClientEmploye === false;
//    return this.authService.GetCurrentUser().userRole == 'GLOBAL_ADMIN' || this.authService.GetCurrentUser().userRole == 'SUPERVISEUR';
  }

  // affiche orderGlobalStatus, billToCustomer.customerName et userGroupAssigned.userGroupDescription dans la liste des commandes
  public canSeeOrderListAll(user?: User): boolean {
    return this.authService.GetCurrentUser().currentUserIdClientEmploye === false;
//    return this.authService.GetCurrentUser().userRole == 'GLOBAL_ADMIN' || this.authService.GetCurrentUser().userRole == 'SUPERVISEUR';
  }

  // REPORT


  // ADDRESS-POPUP (CHECKOUT)

  // permet au superviseur de shipper à l'adresse du client par default au lieu de l'adresse du user group par default
  public canShipToCustomerAddress(user?: User): boolean {
    return this.authService.GetCurrentUser().currentUserIdClientEmploye === false;
//    return this.authService.GetCurrentUser().userRole == 'SUPERVISEUR';
  }

  // CHECKOUT

  // permet de choisir sa propre adresse pour une expédition
  public canChooseOwnShippingAddress(user?: User): boolean {
    return this.authService.GetCurrentUser().currentUserIdClientEmploye === false;
//    return this.authService.GetCurrentUser().userRole == 'SUPERVISEUR';
  }

  // force le superviseur a entrer un #PO au checkout
  public mustEnterPONumberAtCheckout(user?: User): boolean {
    return this.authService.GetCurrentUser().currentUserIdClientEmploye === false;
//    return this.authService.GetCurrentUser().userRole == 'SUPERVISEUR';
  }

  // applique les restriction du user group lors du checkout
  public mustApplyUserGroupRestriction(user?: User): boolean {
    return this.authService.GetCurrentUser().currentUserIdClientEmploye;
  }


  /*

    SIDENAV : il reste une utilisaton de authService.userRole pour de l'Affichage

    SUPERVISOR-POPUP: todo : on comprare directement un chapms de la DB (mainUserType eq 'SUPERVISEUR'), est ce que cette info sera entretenue?

  */

  public TestSimpleClaimExistance(claimName:permissionCodeEnum) : boolean{
    let TestExist= this.authService.GetCurrentUser().listPrivilegeByClaims[claimName];
    if ( TestExist != null &&  TestExist.length > 0)
      return true;
    else
      return false;

  }
}
