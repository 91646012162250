import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { State, toODataString } from '@progress/kendo-data-query';

import { Product } from './product.model';
import { UtilService } from 'src/app/shared/util.service';

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  private products: ReplaySubject<Product[]> = new ReplaySubject<Product[]>(1);
  public product: ReplaySubject<Product> = new ReplaySubject<Product>(1);
  public productsOdata: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private http: HttpClient,
    private utilService: UtilService
  ) {}

  initOne() {
    this.product.next({
      isActiveInSafestock: false,
      productId: 0,
      productListPrice: 0,
      productMainImageUrl: '',
      productNumber: '',
      productTitle: '',
      productTitleEn: '',
      productTitleFr: '',
      productBrand: '',
      productDescriptionEn: '',
      productDescriptionFr: '',
      productCustomField01Name: "",
      productCustomField02Name: "",
      productCustomField03Name: "",
      productCustomField01Value: "",
      productCustomField02Value: "",
      productCustomField03Value: "",
    });
  }

  list(): Observable<Product[]> {
    return this.http.get<Product[]>(apiUrl + 'product/Products/Get', {}).pipe(
      tap((response: any) => {
        this.products.next(response);
      })
    );
  }

  read(id: string): Observable<Product> {
    return this.http.get<Product>(apiUrl + 'product/Products/' + id, {}).pipe(
      tap((response: any) => {
        // conversion des champs date ici?
        this.product.next(response);
      })
    );
  }

  getProductInfo(id: number) {
    return this.http
      .get<Product>(apiUrl + 'product/Catalog/GetDetailProductInfo/' + id, {})
      .pipe(
        tap((response: any) => {
          // conversion des champs date ici?
          this.product.next(response);
        })
      );
  }

  create(product: any): Observable<any> {
    // todo: potentiel manipulation a faire avec les champs date, checkbox, etc
    return this.http.post<any>(apiUrl + 'product/Products/', product).pipe(
      tap((response: any) => {
        //this.product.next(response); // TODO: réactiver quand l'API va me retourner le record comme dans un get
        // TODO : refresh de la variable this.products avec le nouveau record
      })
    );
  }

  update(product: any): Observable<any> {
    // todo: potentiel manipulation a faire avec les champs date, checkbox, etc
    let uri = `${apiUrl}product/Products/${product.productId}`
    return this.http.put<any>(uri, product)

  }

  // ODATA : STATE

  odata(state: State): Observable<any> {
    let _state = this.utilService.makeStateCaseInsensitive(state)
    let uri = `${apiUrl}product/Products?${toODataString(_state)}&$count=true`
    return this.http.get<any>(uri).pipe(
      tap((response: any) => {
        this.productsOdata.next(response);
      })
    );
  }

  // ODATA  : STRING

  odataStr(state: State, oDataString: string): Observable<any> {
    let _state = this.utilService.makeStateCaseInsensitive(state);
    let oDataFullString = this.utilService.getODataFullString(_state, oDataString);
    let uri = `${apiUrl}product/Products?${oDataFullString}&$count=true`
    return this.http.get<any>(uri);
  }

  odataStrProductItems(state: State, oDataString: string): Observable<any> {
    let oDataFullString = this.utilService.getODataFullString(state, oDataString);
    let uri = `${apiUrl}product/ProductItems?${oDataFullString}&$count=true`
    return this.http.get<any>(uri);
  }

  assignAllProductODataStr(customerId: number, userGroupHeaderId: number, state: State, oDataString: string): Observable<any> {
    // TODO: ajuster ici quand la partie backend sera faite
    let oDataFullString = this.utilService.getODataFullString(state, oDataString);
    let uri = '';
    if (customerId)
      uri = `${apiUrl}product/AssignProducts?CustomerId=${customerId}&${oDataFullString}`;
    else if (userGroupHeaderId)
      uri = `${apiUrl}product/AssignProducts?UserGroupId=${userGroupHeaderId}&${oDataFullString}`;
    return this.http.post<any>(uri, []);
  }

  getProductRelations(productId: number, clientId: number, userGroupId: number): Observable<any> {
    return this.http.get<Product>(`${apiUrl}product/Products/ProductRelations?ProductId=${productId}&CustomerId=${clientId}&UserGroupID=${userGroupId}&ReturnDetailedEntitiesData=false`, {}).pipe(
      tap((response: any) => {
        return response;
      })
    );
  }

}
