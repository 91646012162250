<kendo-dialog-titlebar (close)="close()">
  <div style="font-size: 18px; line-height: 1.3em">
    <kendo-svg-icon [icon]="allIcons.lockIcon" themeColor="primary"></kendo-svg-icon>
    {{ "module.auth.reset-password.title" | translate }}
  </div>
</kendo-dialog-titlebar>
<!-- FORM -->

<form
  class="k-form-vertical ss-edit-form"
  #editform="ngForm"
  (ngSubmit)="changePassword()"
>
  <!--logo safestock-->
  <div class="ss-pop-logo">
    <img src="../../assets/img/logo-safestock.png" alt="Logo Safestock" />
  </div>
  <!-- OLD PASSWORD -->
  <div class="ss-formfield" *ngIf="modifySelf">
    <kendo-label
      [for]="oldpassword"
      [text]="'module.auth.reset-password.label_oldPassword' | translate"
    ></kendo-label>
    <input
      kendoTextBox
      type="password"
      name="oldpassword"
      #oldpassword
      [(ngModel)]="oldPassword"
    />
  </div>
  <!-- NEW PASSWORD -->
  <div class="ss-formfield">
    <kendo-label
      [for]="newpassword"
      [text]="'module.auth.reset-password.label_newPassword' | translate"
    ></kendo-label>
    <input
      kendoTextBox
      type="password"
      name="newpassword"
      #newpassword
      [(ngModel)]="newPassword"
    />
  </div>
  <!-- PASSWORD CONFIRM -->
  <div class="ss-formfield">
    <kendo-label
      [for]="confirmpassword"
      [text]="'module.auth.reset-password.label_confirmPassword' | translate"
    ></kendo-label>
    <input
      kendoTextBox
      type="password"
      name="confirmpassword"
      #confirmpassword
      [(ngModel)]="confirmPassword"
    />
  </div>
  <!-- NÉCESSAIRE POUR SUBMIT DE LA FORM AVEC ENTER -->
  <button style="display: none">ok</button>

  <!-- ERROR MESSAGE -->
  <kendo-label class="ss-pop-password-error">{{
    errorKey | translate
  }}</kendo-label>
</form>

<!-- BUTTONS -->

<kendo-dialog-actions>
  <button
    kendoButton
    themeColor="primary"
    [svgIcon]="allIcons.cancelIcon"
    fillMode="flat"
    (click)="close()"
  >
    {{ "shared.button.cancel" | translate }}
  </button>
  <button
    kendoButton
    themeColor="primary"
    [svgIcon]="allIcons.checkIcon"
    (click)="changePassword()"
  >
    {{ "module.auth.reset-password.button_ok" | translate }}
  </button>
</kendo-dialog-actions>
