<div
  [class.ss-catalog-item-detail-full-page]="!popupMode"
  class="ss-catalog-item-detail"
>

  <!-- IMAGE -->

  <div class="ss-catalog-item-detail-img">
    <img
      *ngIf="!popupMode; else smaller_image"
      [src]="imageURI"
    />
    <ng-template #smaller_image>
      <img
        style="max-width: 250px"
        [src]="imageURI"
      />
    </ng-template>
  </div>

  <!-- TITRE -->

  <div class="ss-catalog-item-detail-info-col">
    <p class="ss-catalog-item-detail-name">
      {{
        translate(
          product.product.productTitleFr,
          product.product.productTitleEn
        )
      }}
    </p>

    <!-- CODE SPI -->

    <p class="ss-catalog-item-detail-text ss-separator">
      <span class="text-bold">
        {{ "module.catalog.productModel.spiCode" | translate }}
      </span>
      : {{ product.product.productNumber }}
    </p>

    <!-- DESCRIPTION -->

    <p class="ss-catalog-item-detail-descr" *ngIf="!popupMode">
      {{
        translate(
          product.product.productDescriptionFr,
          product.product.productDescriptionEn
        )
      }}
    </p>

    <!-- MARQUE -->

    <p class="ss-catalog-item-detail-text" *ngIf="product.product.productBrand.length > 0">
      <span class="text-bold">
        {{ "module.catalog.productModel.productBrand" | translate }}
      </span>
      : {{ product.product.productBrand }}
    </p>

    <!-- CHAMPS CUSTOM -->

    <!--************************-->
    <!--Champ Custom 01-->
    <p class="ss-catalog-item-detail-text" *ngIf="product.product.productCustomField01Value || product.product.productCustomField01Name">
      <span class="text-bold">
        {{ product.product.productCustomField01Name }}
      </span>

      <!--le ':' ne va s'afficher que si le nom ET la valeur sont remplits.. -->
      <span *ngIf="product.product.productCustomField01Value && product.product.productCustomField01Name">:</span>

      <!--Si la valeur est un URI,  on le href,  sinon, simple SPAN...-->
      <span [ngSwitch]="IsAnUrlLink(product.product.productCustomField01Value)">
        <span *ngSwitchCase="true">
          <!--Si c'est un URI-->
          <a [href]="product.product.productCustomField01Value"
                target="_blank">
              {{ product.product.productCustomField01Value }}
          </a>
        </span>
          <!--Si c'est PAS un URI-->
          <span *ngSwitchCase="false">
          {{ product.product.productCustomField01Value }}
        </span>
      </span>
    </p>
    <!--************************-->


    <!--************************-->
    <!--Champ Custom 02-->
    <p class="ss-catalog-item-detail-text" *ngIf="product.product.productCustomField02Value || product.product.productCustomField02Name">
      <span class="text-bold">
        {{ product.product.productCustomField02Name }}
      </span>

      <!--le ':' ne va s'afficher que si le nom ET la valeur sont remplits.. -->
      <span *ngIf="product.product.productCustomField02Value && product.product.productCustomField02Name">:</span>

      <!--Si la valeur est un URI,  on le href,  sinon, simple SPAN...-->
      <span [ngSwitch]="IsAnUrlLink(product.product.productCustomField02Value)">
        <span *ngSwitchCase="true">
          <!--Si c'est un URI-->
          <a [href]="product.product.productCustomField02Value"
                target="_blank">
              {{ product.product.productCustomField02Value }}
          </a>
        </span>
          <!--Si c'est PAS un URI-->
          <span *ngSwitchCase="false">
          {{ product.product.productCustomField02Value }}
        </span>
      </span>
    </p>
    <!--************************-->

    <!--************************-->
    <!--Champ Custom 03-->
    <p class="ss-catalog-item-detail-text" *ngIf="product.product.productCustomField03Value || product.product.productCustomField03Name">
      <span class="text-bold">
        {{ product.product.productCustomField03Name }}
      </span>

      <!--le ':' ne va s'afficher que si le nom ET la valeur sont remplits.. -->
      <span *ngIf="product.product.productCustomField03Value && product.product.productCustomField03Name">:</span>

      <!--Si la valeur est un URI,  on le href,  sinon, simple SPAN...-->
      <span [ngSwitch]="IsAnUrlLink(product.product.productCustomField03Value)">
        <span *ngSwitchCase="true">
          <!--Si c'est un URI-->
          <a [href]="product.product.productCustomField03Value"
                target="_blank">
              {{ product.product.productCustomField03Value }}
          </a>
        </span>
          <!--Si c'est PAS un URI-->
          <span *ngSwitchCase="false">
          {{ product.product.productCustomField03Value }}
        </span>
      </span>
    </p>
    <!--************************-->

    <!-- PROPRIÉTÉS DU PRODUIT -->

    <div>
      <p></p>
      <p></p>
      <p></p>
    </div>
    <div *ngFor="let property of itemProperties">
      <p class="ss-catalog-item-detail-text ss-catalog-item-detail-property">
        <!-- LA donnée provient d'un Subject, donc le async est safe -->
        <span class="text-bold">{{ transformPropName(property) | async }}</span>
      </p>
      <kendo-buttongroup
        selection="single"
        class="ss-catalog-property-row"
        selection="single"
      >
        <button
          kendoButton
          class="ss-catalog-button-properties"
          *ngFor="
            let buttonValue of sortArrayOfPropertiesValues(property)
          "
          kendoButton
          (click)="buttonSelected(property, buttonValue)"
          [toggleable]="true"
          [value]="buttonValue"
          [name]="'radio-button-' + property"
          [class.ss-catalog-button-properties-active]="
            checkIfButtonIsActive(property, buttonValue)
          "
          [selected]="checkIfIsOnlyButtonInProperty(property)"
        >
          {{ transformPopValueToName(property, buttonValue) }}
        </button>
      </kendo-buttongroup>
    </div>
    <p class="ss-item-properties-separator" *ngIf="itemProperties.length > 0"></p>

    <!-- NOTE : seulement pour les Produit à la demande et les usager qui ne sont pas ClientEmploye -->

    <div *ngIf="isProductOnRequest || canEnterANote">
      <div *ngIf="isProductOnRequest" class="ss-catalog-item-detail-pald-title">{{ "module.catalog.titreNoteProductOnRequest" | translate }}</div>
      <div *ngIf="!isProductOnRequest" class="ss-catalog-item-detail-pald-title">{{ "module.catalog.titreNote" | translate }}</div>
      <kendo-textarea
        [rows]="3"
        resizable="vertical"
        [(value)]="note01"
      ></kendo-textarea>
    </div>

    <!-- qty en main -->
    <p class="ss-catalog-item-detail-stockAvailability" [style.visibility]="getStockNonStock() != 'N/A' ? 'visible' : 'hidden'">
      {{ "module.catalog.productModel.stockAvailabilityTitle" | translate }} : {{ stockAvailability }}
    </p>

    <!-- PRIX -->

    <p *ngIf="canSeePrice" class="ss-catalog-item-detail-price">
      {{ "module.catalog.productModel.price" | translate }} : {{ getPrice() }}
    </p>
    <div class="ss-catalog-item-detail-control-input">
      <div id="item-detail-inputs-flex-container">

        <!-- INPUT QUANTITÉ -->

        <kendo-numerictextbox
          format="n0"
          [(value)]="quantitySelected"
          [autoCorrect]="true"
          [min]="1"
          [max]="100"
          (blur)="forceMinimumValue()"
        >
        </kendo-numerictextbox>

        <!-- BUTTON ADD TO CART -->

        <button
          kendoButton
          [disabled]="isDisabledAddToCart"
          (click)="addToCart()"
          class="ss-catalog-item-detail-addButton"
        >
          <mat-icon>add_shopping_cart</mat-icon>
        </button>

        <!-- WARNING PUR INDIQUER A L'USAGER POURQUOI LE BOUTON ADD TO CART EST DISABLED -->

        <div class="ss-catalog-add-to-cart-disable-warning"><span *ngIf="isDisabledAddToCart && !isLoading">{{ "module.catalog.labelAddToCartDisabled" | translate }}</span></div>
      </div>

      <!-- BACK TO CART BUTTON -->

      <button
        *ngIf="caller == 'catalog'"
        (click)="navigateToCart()"
        class="ss-catalog-item-detail-text"
        kendoButton
        themeColor="light"
        id="ss-catalog-item-delail-see-cart-button"
      >
        {{ "module.catalog.seeCartButton" | translate }}
      </button>
    </div>
  </div>
</div>
