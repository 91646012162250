import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';

import { MaterialModule } from './material.module';
import { KendoModule } from './kendo.module';

import { PopAlertComponent } from './component/pop-alert/pop-alert.component';
import { PopConfirmComponent } from './component/pop-confirm/pop-confirm.component';
import { LoaderComponent } from './component/loader/loader.component';
import { AddToCartPopupComponent } from 'src/app/module/catalog/add-to-cart-popup/add-to-cart-popup.component';
import { ProductItemPopupComponent } from '../module/product/product-item-popup/product-item-popup.component';
import { ItemDetailComponent } from 'src/app/module/catalog/item-detail/item-detail.component';
import { AfterValueChangedDirective } from './after-value-changed.directive';
import { CustomerDescriptionPipe } from './pipe/customer-description.pipe';
import { UserGroupDescriptionPipe } from './pipe/user-group-description.pipe';
import { OrderDetailQuantityShippedPipe } from './pipe/order-detail-quantity-shipped.pipe';

@NgModule({
  declarations: [
    PopAlertComponent,
    PopConfirmComponent,
    LoaderComponent,
    AddToCartPopupComponent,
    ProductItemPopupComponent,
    ItemDetailComponent,
    AfterValueChangedDirective,
    CustomerDescriptionPipe,
    UserGroupDescriptionPipe,
    OrderDetailQuantityShippedPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    KendoModule,
    TranslateModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    KendoModule,
    TranslateModule,
    LoaderComponent,
    AddToCartPopupComponent,
    ProductItemPopupComponent,
    ItemDetailComponent,
    AfterValueChangedDirective,
    CustomerDescriptionPipe,
    UserGroupDescriptionPipe,
    OrderDetailQuantityShippedPipe,
  ],
})
export class SharedModule {}
