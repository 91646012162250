<kendo-dialog
  title="{{ 'module.user.import.title' | translate }}"
  (close)="close()"
  [width]="dialogWidth"
  [height]="dialogHeight"
  class="ss-order-edit-product-dialog-content"
  [animation]="false"
>
  <!-- ETAPE 1 : SÉLECTIONNER LE USER GROUP ------------------------------------------ -->

  <div class="ss-user-import-grid-container" *ngIf="step == 1">
    <!-- MESSAGE -->

    <div class="ss-user-import-message">
      {{ "module.user.import.message_user_group" | translate }}
    </div>

    <!-- GRID -->

    <kendo-grid
      [data]="gridView"
      [pageSize]="state.take"
      [skip]="state.skip"
      [sort]="state.sort"
      [filter]="state.filter"
      [sortable]="true"
      [pageable]="pageable"
      [filterable]="true"
      [resizable]="true"
      [selectable]="gridService.selectableSettings"
      [loading]="isLoading"
      scrollable="scrollable"
      class="ss-list-grid"
      (cellClick)="selectionner($event)"
      (dataStateChange)="dataStateChange($event)"
    >
      <!-- COLUMNS -->

      <!-- ID -->

      <kendo-grid-column
        field="userGroupHeaderId"
        title="{{ 'module.user-group.model.userGroupHeaderId' | translate }}"
        [width]="40"
        [minResizableWidth]="80"
        filter="numeric"
        format="0n0"
      ></kendo-grid-column>

      <!-- DESCRIPTION -->

      <kendo-grid-column
        field="userGroupDescription"
        title="{{ 'module.user-group.model.userGroupDescription' | translate }}"
        [width]="200"
        [minResizableWidth]="240"
      ></kendo-grid-column>

      <!-- CUSTOMER -->

      <kendo-grid-column
        field="customer.customerName"
        title="{{ 'module.user-group.model.customerName' | translate }}"
        [width]="80"
        [minResizableWidth]="160"
      ></kendo-grid-column>
    </kendo-grid>
  </div>

  <!-- ETAPE 2 : SÉLECTIONNER UN FICHIER ------------------------------------------ -->

  <div class="ss-user-import-grid-container" *ngIf="step == 2">
    <!-- MESSAGE -->

    <div class="ss-user-import-message">
      {{ "module.user.import.message_file" | translate }}
    </div>

    <div class="ss-user-import-file">
      <div
        class="ss-user-import-file-drop-zone"
        kendoUploadDropZone="uploadZone"
      >
        {{ "module.user.import.message_drop_file_here" | translate }}
      </div>
      <kendo-fileselect
        zoneId="uploadZone"
        [multiple]="false"
        (valueChange)="onFileChage($event)"
      >
      </kendo-fileselect>
    </div>
  </div>

  <!-- ETAPE 3 : CONFIRMER ET IMPORTER ------------------------------------------ -->

  <div class="ss-user-import-grid-container" *ngIf="step == 3">
    <!-- MESSAGE -->

    <div class="ss-user-import-message">
      {{ "module.user.import.message_confirm" | translate }}
    </div>

    <div class="ss-user-import-confirm">
      <div class="ss-user-import-confirm-value">
        User Group : <b>{{ userGroupDescription }}</b>
      </div>
      <div class="ss-user-import-confirm-value">
        File : <b>{{ file.name }}</b>
      </div>
    </div>
  </div>

  <!-- BUTTONS -->
  <kendo-dialog-actions *ngIf="step == 3">
    <button
      kendoButton
      themeColor="primary"
      [svgIcon]="allIcons.xIcon"
      fillMode="flat"
      (click)="close()"
    >
      {{ "shared.button.cancel" | translate }}
    </button>
    <button kendoButton themeColor="primary" [svgIcon]="allIcons.checkIcon" (click)="confirm()">
      {{ "module.user.import.button_confirm" | translate }}
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
