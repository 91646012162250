import { Component, OnInit } from '@angular/core';
import { State } from '@progress/kendo-data-query';
import { DialogRef, DialogContentBase } from '@progress/kendo-angular-dialog';
import { GridDataResult } from '@progress/kendo-angular-grid';
import * as svgIcons from '@progress/kendo-svg-icons';

import { ProductItemService } from '../product-item.service';
import { GridService } from 'src/app/shared/grid.service';
import { ProductService } from '../../product/product.service';
import { UtilService } from 'src/app/shared/util.service';

@Component({
  selector: 'app-product-item-popup',
  templateUrl: './product-item-popup.component.html',
  styleUrls: ['./product-item-popup.component.scss'],
})
export class ProductItemPopupComponent extends DialogContentBase implements OnInit {
  public allIcons = svgIcons;
  
  title = '';
  button_cancel = '';

  isLoading = false;
  gridView!: GridDataResult;
  id!: number;
  
  pageable = {
    pageSizes: this.gridService.pageSizes,
  };

  public state: State = {
    take: this.gridService.take,
    skip: 0,
  };
  public productItemTitle = this.utilService.getPropertyTranslated('productItemTitleFr','productItemTitleEn');

  constructor(
    public override dialog: DialogRef,
    public gridService: GridService,
    private productItemService: ProductItemService,
    private productService: ProductService,
    private utilService: UtilService,
  ) {
    super(dialog);
    // fetch productitem
    let oDataStr = "$select=productItemId,productItemNumber,productItemTitleFr,productItemTitleEn";
    this.isLoading = true;
    this.productItemService.odataStr(this.state, oDataStr).subscribe(response => {
      this.isLoading = false;
      this.gridView = {
        data: response.value,
        total: response['@odata.count'],
      };
    })
  }

  ngOnInit(): void {}

  cancel() {
    this.dialog.close(false);
  }

  select(e: any) {
    this.dialog.close(e.dataItem);
  }

  dataStateChange(state: State) {
    this.isLoading = true;
    this.gridService.setRowPerPages(state);
    this.state = state;
    this.productItemService.odata(this.state).subscribe((response) => {
      this.isLoading = false;
      this.gridView = {
        data: response.value,
        total: response['@odata.count'],
      };
    });
  }
}
