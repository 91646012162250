/**
 * Liste des clé Window.localStorage utilisées dans l'application.
 */
export enum LocalStorage {
  LANG = 'lang',
  JWT = 'jwt_content',
  JWT_IMPERSONATOR = 'JWT_IMPERSONATOR',
  JWT_SOURCE_SERVER = 'jwt_source_server',
  JWT_REFRESH_TOKEN = 'JWT_REFRESH_TOKEN', //Ce token permet de demander un refresh du JWT [le access token]
  SIDENAV_EXPANDED = 'sidenav_expanded',
  GRID_ITEM_PER_PAGE = 'grid_item_per_page',
  LOGIN_REMEMBER_ME = 'login_remember_me',
  LOGIN_LAST_CODE = 'login_last_code',
  LOGIN_PREFERED_METHOD = 'login_prefered_method',
  CUSTOMER_PRODUCT_ONLY = 'customer_prodcut_only',
  USER_GROUP_EMPLOYEE_ONLY = 'user_group_employee_only',
  USER_GROUP_PRODUCT_ONLY = 'user_group_product_only',
  CART = 'cart_items',
  CART_NOTES = 'cart_notes',
  USERGROUP_RESTRICTIONS = 'user_group_restrictions',
  PRODUCT_RESTRICTIONS = 'product_restrictions',
  USER_GROUP_HEADER_INFO = 'user_group_header_info',
  USER_ORDER_OVERVIEW = 'user_order_overview',
  USER_OVERRIDE = 'user_override',
  USER_FULL_NAME = "user_full_name",
  USER_FIRST_NAME = "user_first_name",
  USER_LAST_NAME = "user_last_name",
  USER_EMPLOYEE_PERSONNAL_NOTES = "user_employee_personnal_notes",
  CUSTOMER_SHIPTO_ID = "customer_shipto_id",
  CURRENT_CUSTOMER_ID = "current_customer_id",
  CVL_FIELDS_DATA = "cvl_fields_data",
  CVL_DATA = "cvl_data",
  CART_ITEMS_DETAILS = "cart_items_Details",
  CACHE_CUSTOMER = "cache_customer",
  CACHE_USER_GROUP = "cache_user_group",
  REDIRECT_URL = "redirect_url",
}
